@import "./../../scss/variables";
@import "./../../scss/mixin";

.panel {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	margin-bottom: 10px;

	&__header {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		//background: $color_gray_t
	}

	&__body {

	}
}