.material__img-container {
  position: relative;
  display: inline-block;
}

.material__img {
  display: block;
  width: 45px;
  height: 30px;
  object-fit: cover;
  transition: transform var(--transition) ease-in-out;

  &--border {
    border: var(--border-width-thin) solid #6a7178;
  }
}

.material__img--big {
  display: none;
}

.material__img-container:hover .material__img {
  transform: scale(2.7);
}