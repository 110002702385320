.infoPage{
  background: var(--white-100);

  @media (width <= 480px) {
    max-width: 480px
  }

  .sale-info {
    margin-top: 60px;

    @media (width <= 992px) {
      margin-top: 30px;
    }
  }

  &__main{
    padding-top: 90px;
    padding-bottom: 70px;
    background-image: url('../../media/welcomeKm.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &-block{
      color: var(--white-100);

      &-second{
        display: flex;
        justify-content: space-between;

      }

      &-titleFirst{
        font-weight: var(--bold-weight);
        font-size: 43px;
        line-height: 51.6px;
        text-align: left;
        text-transform: uppercase;
      }

      &-titleSecond{
        font-weight: var(--bold-weight);
        font-size: 125px;
        line-height: 195.5px;

        @media (width <= 480px) {
          max-width: 480px;
          font-size: 35px;
          line-height: 85.5px;

        }
      }

      &-desc-hint{
        font-size: 20px;
        line-height: 120%;
      }

      &-desc-hint + &-desc-hint {
        margin-top: 10px;
      }

      &-desc-extra {
        font-weight: var(--light-weight);
        font-size: 18px;
      }

      &-imgIcon{
        width: 32px;
        height: 32px;
        margin-top: 5px;
      }

      &-imgScroll{
        width: 25px;
        height: 56px;
      }

      &-main{
        display: flex;
        justify-content: center;
        margin-top: 30px;

        @media (width <= 775px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      &-registerSale {
        display: flex;
        gap: 0;
        align-items: center;
        width: 1073px;
        height: 57px;
        margin-top: 15px;
        padding: 11.7px 106.49px 13.7px 15px;
        border-radius: 30px 10px 10px 30px;
        background: linear-gradient(90deg, #FFFFFF28 0%, #FFFFFF00 96.1%);
        box-shadow: 0 4px 130px 0 #00000040;

        &-info {
          margin-left: 23px;
        }

        &-main {
          font-weight: var(--medium-weight);
        }

        &-second {
          color: #E0E0E0;

        }
      }

      &-sale{
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 350px;
        max-width: 550px;
        height: 71px;
        margin-right: 20px;
        padding-left: 30px;
        border-radius: 62px;
        background: #7A7777;
        box-shadow: 0 4px 130px 0 #00000040;

        @media (width <= 775px) {
          margin-top: 15px;
        }

        &-main{
          display: flex;
          margin-left: 20px;

          &-text{
            display: flex;
            align-items: center;
            margin-left: 10px;
            font-size: 20px;
          }

          &-text2{
            display: flex;
            flex-direction: column;
            margin-left: 10px;

          }
        }
      }

      &-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 90px;
      }

      &-scroll{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 60px;
      }

      &-btnTitle{
        display: flex;
        align-items: center;

        &-text{
          margin-left: 15px;
        }
      }
    }
  }

  &__lessons{
    width: 100%;
    height: 170px;
    background: var(--gray-75);

    &-main{
      max-width: 100%;
      height: 753px;
      margin: 104px 168px 0;

      @media (width <= 480px) {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      &-titleFirst{
        margin-bottom: 38px;
        color: var(--purple-400);
        font-size: 18px;
      }

      &-titleSecond{
        padding-top: 100px;
        font-weight: var(--medium-weight);
        font-size: 40px;

        @media (width <= 480px) {
          padding-top: 25px;

        }
      }
    }
  }

  &__slider{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 700px;
    margin: auto;
    background: var(--gray-75);

    @media (width <= 480px) {
      height: 100%;
      min-height: 1000px;

    }

  }

  &__service{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 55px 150px;

    @media (width <= 575px) {
      padding: 55px 200px;
    }

    &-titleMain{
      margin-top: 84px;
      margin-bottom: 50px;
      font-weight: var(--medium-weight);
      font-size: 40px;
    }

    &-KM{
      margin-top: 71px;
      color: var(--purple-400);
      font-weight: var(--medium-weight);
      font-size: 40px;
    }

    &-title{
      width: 70%;
      margin-top: 20px;
      margin-bottom: 35px;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
    }

    &-cards{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (width <= 480px) {
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &-card{
        display: flex;
        flex: 1 0 calc(33.3333% - 30px);
        flex-direction: column;
        align-items: center;
        height: 261px;
        margin: 15px;
        background-color: var(--white-100);
        box-shadow: 5px 34px 134.9px 0 #D4D6E2A6;

        @media (width <= 575px) {
          flex: 1 0 calc(50% - 30px);
        }

        @media (width <= 480px) {
          flex: 1 0 calc(100% - 3px);
          height: 100%;
          padding-right: 20px;
          padding-left: 20px;

        }

        &-imgBlock{
          height: 186px;
        }

        &-img{
          margin-top: 70px ;
          object-fit: cover;

        }

        &-text{
          max-width: 360px;
          margin-bottom: 10px;
          line-height: 19px;
          text-align: center;
        }
      }
    }

    &-registration{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 55px;
      margin-bottom: -55px;

      &-text{
        margin-left: 15px;
        font-weight: var(--medium-weight);
        font-size: 18px;

        @media (width <= 480px) {
          min-width: 200px;
          margin-bottom: 30px;
        }
      }

      @media (width <= 480px) {
        display: flex;
        flex-direction: column;
      }
    }

  }

  &__learning{
    display: flex;
    padding-block: 168px;
    justify-content: space-between;

    @media (width <= 480px) {
      padding-block: 68px;

    }

    &-KM{
      width: 759px;

      @media (width <= 480px) {
        width: 100%;
        max-width: 480px;
      }
    }

    &-telegram{
      width: 100%;
      max-width: 642px;
      height: 100%;
      max-height: 251px;
      border-radius: 20px;
      background: var(--white-100);
      box-shadow: 5px 34px 134.9px 0 #D4D6E2C4;

      &-line{
        width: 90%;
        height: 1px;
        margin-top: 32px;
        margin-left: 30px;
        border: var(--border-width-thin) solid #0000001F;
      }

      &-btn{
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 22px;
      }

      &-block{
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-right: 80px;
        margin-left: 47px;

        @media (width <= 480px) {
          max-width: 480px;
          margin-top: 30px;
        }

        &-text{
          margin-left: 35px;
          font-size: 25px;
          line-height: 30px;
          text-align: left;

          @media (width <= 480px) {
            font-size: 22px;
            text-align: left;
          }
        }

        @media (width <= 480px) {
          margin-left: 25px;
        }
      }

      @media (width <= 480px) {
        max-width: 480px;
        max-height: 500px;
        margin-top: 30px;
      }
    }

    &-imgVector{
      position: relative;
      bottom: 120px;
      left: 310px;

      @media (width <= 480px) {
        display: none;
      }
    }

    &-imgTelegram{
      width: 65px;
      height: 63px;
    }


    @media (width <= 1740px) {
      flex-direction: column;
      align-items: center;
    }

    &-images{
      display: flex;
      flex-direction: column;
      margin-right: 90px;
    }

    &-info{
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &-title{
        font-weight: var(--medium-weight);
        font-size: 40px;
        text-align: left;

        @media (width <= 480px) {
          font-size: 30px;
          text-align: center;
        }
      }

      &-titleSecond{
        margin-top: 15px;
        margin-bottom: 57px;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
      }

      &-titleRed{
        color: var(--purple-400);

      }

      &-sale{
        margin-top: auto;
        padding: 19px 10px;
        font-weight: var(--medium-weight);
        text-align: center;
        background: var(--gray-75);

        @media (width <= 575px) {
          margin-top: 0;
        }
      }

      &-links{
        margin: 50px 0;

        @media (width <= 575px) {
          margin-top: 30px;
        }

        &-link{
          display: flex;
          flex-direction: column;
          margin-top: 45px;

          &-name{
            line-height: 19px;
            text-align: left;
          }

          &-url{
            color: var(--purple-400);

          }
        }
      }
    }
  }

  &__constructor{
    display: flex;
    background: var(--gray-75);

    &-main{
       margin: 103px 153px;

      @media (width <= 480px) {
        margin: 103px 25px;

      }

      &-title{
        margin-left: 15px;
        font-weight: var(--medium-weight);
        font-size: 40px;

        @media (width <= 480px) {
          font-size: 30px;

        }
      }

        &-cards{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 70px;

          &-card{
            position: relative;
            display: flex;
            flex: 1 0 calc(25% - 30px);
            align-items: center;
            justify-content: center;
            height: 261px;
            margin: 15px ;
            background-color: var(--white-100);

            @media (width <= 575px) {
              flex: 1 0 calc(50% - 30px);
            }

            @media (width <= 480px) {
              flex: 1 0 calc(50% - 3px);
            }

            &-main{
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: space-between;
              height: 70px;
              margin-right: 20px;

              @media (width <= 480px) {
                width: 100%;
                max-width: 180px;
                margin-left: 30px;
              }
            }

            &-text{
              margin-bottom: 6px;
              font-weight: var(--medium-weight);
              font-size: 21px;
              line-height: 25px;
              text-align: right;
            }

            &-blockImg{
              margin-right: 30px;
            }

            &-img{
              position: absolute;
              bottom: 0;
              left: 14px;
            }

            &-link{
              position: absolute;
              bottom: 0;
              left: 14px;
            }
          }
        }

        &-restrictions{
          display: flex;
          align-items: center;
          margin: 50px 0 30px 15px;

          &-text{
            margin-right: 23px;
            font-weight: var(--medium-weight);
            line-height: 19px;
            text-align: left;
          }
        }
    }
  }

  &__footer {
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    color: var(--white-100);
    background: var(--purple-400);

    &-main{
      margin-left: 207px;

      @media (width <= 480px) {
        margin-top: 10px;
        margin-left: 57px;

      }
    }

    &-location{
      margin-left: 30px;
    }

    &-text{
      margin-right: 168px;
      font-size: 14px;
      line-height: 21px;

      @media (width <= 480px) {
        margin-right: 68px;

      }
    }

    @media (width <= 480px) {
      height: 100%;
    }
  }
}

.container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 168px;
    padding-left: 168px;

    @media (width <= 480px) {
      padding-right: 48px;
      padding-left: 48px;
    }
}