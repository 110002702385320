// Type File
.file-upload {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-bottom: var(--border-width-thin) solid #ECEFF1;
  color: var(--gray-700);
  line-height: 100%;
  cursor: pointer;
  transition: var(--transition) all;
  user-select: none;

  &::after {
    position: absolute;
    right: 20px;
    width: 22px;
    height: 20px;
    background-image: url(../media/download.svg);
    background-size: cover;
    opacity: 0;
    transition: var(--transition) all;
    content: '';
  }

  &:hover {
    background: #FFEDF9;

    &.activated::after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }

  .name {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: #6A7178;
    line-height: 100%;
    text-align: right;

    .remove {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      border: none;
      background-color: inherit;
      background-image: url(../media/trash-o.svg);
      outline: none;
      cursor: pointer;
    }
  }
}