.connections {
	&__header {
		&-title {
			font-weight: 400;
		}
	}

	&-item {
		&__header {
			&-title {
				font-weight: 400;
			}
		}
	}
}