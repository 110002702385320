.cuttingParams {
  display: flex;
  flex-direction: column;
  width: 377px;
  max-height: 100vh;


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
  }

  &__body {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    padding: var(--modal-indent);

    &-strips{
      &-title{
        margin-bottom: 15px;
        font-weight: var(--medium-weight);
        background: var(--gray-50);
      }

      &-body{
        display: flex;
        justify-content: space-between;
        background: var(--white-100);
      }

      &-select{
        background-color: var(--gray-50);
      }

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  &__input {
    width: 100%;
    height: 25px;
    border: none;
    line-height: 20px;
    outline: none;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__select {
    width: 100%;
    height: 40px;
    padding-right: 15px;
    padding-left: 18px;
    border: transparent;
    line-height: 20px;
    background: var(--form-field-bg);
    outline: none !important;
  }

  &__actions{
    display: flex;
    gap: 19px;
    justify-content: flex-end;
    background: #FBFBFB;

    &-check{
      min-width: 20px;
      margin-top: 8px;
      margin-left: 10px;
    }
  }

  &__checkbox{
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &_active{
      background: var(--purple-400);
    }
  }
}

