.roundCheckBox {
  min-width: 27px;
  height: 27px;
  border: 4px solid var(--purple-400);
  border-radius: 50%;
  background: var(--white-100);
  cursor: pointer;

  &_active {
    background: var(--purple-400);
  }
}