@import "./../../scss/variables";
.panel-wrapper-right {
	right: $panelHiddenWidth - $panelWidth;
	top: $headerHeight;
	transition: right 0.4s;

	&.opened {
		right: 0;
		.panel-wrapper-header .icon-button-arrow:after {
			transform: scaleX(1) translate(-50%, -50%);
		}
	}
	.panel-wrapper-header {
		justify-content: flex-start;

		.icon-button-arrow:after {
			transform: scaleX(-1) translate(50%, -50%);
		}
	}
}