.process-scene {
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 1024px;
	height: calc(100vh - 53px);
	margin: 0 30px;

	@media (width <= 480px) {
		width: 100%;
		min-width: 380px;
		margin: 0;
	}
}

.processes {
	width: 100%;
	height: 100%;
	height: -webkit-fill-available;
	user-select: none;

	@media (width <= 480px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-width: 380px;
		margin: 0;
	}

	&__rotate-btn {
		cursor: pointer;
	}

	&__cameraViewBtn{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 33px;
		height: 32px;
		border-radius: var(--radius);
		background: var(--white-100);
		cursor: pointer;
	}

	&__btn_save {
		width: 100%;
		max-width: 250px;
		height: 53px;
		margin-left: auto;
		border: none;
		border-radius: 36px;
		color: var(--white-100);
		font-size: 21px;
		line-height: 26px;
		background-color: var(--purple-400);
		box-shadow: var(--container-shadow);
		cursor: pointer;
		transition: background-color var(--transition);

		&:hover {
			background: var(--white-100);
		}
	}

	&__btns {
		display: flex;
		gap: 16px;
		align-items: center;
		width: 100%;
		padding: 16px 0;

		@media (width <= 480px) {
		    width: 100%;
			max-width: 360px;
		}
	}

	&__workspace {
		position: relative;
		display: flex;
		width: 100%;
		min-height: 543px;
		max-height: 780px;
		background: var(--gray-50);
		box-shadow: var(--container-shadow);

		@media (height >= 800px) {
			height: calc(100% - 173px);
			max-height: 100%;
		}

		@media (width <= 480px) {
			position: relative;
			bottom: 0;
			max-width: 480px;
		}

		&-nav {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			min-width: 137px;
			max-height: calc(100vh - 228px);

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: content-box;
				width: 100%;
				min-height: 40px;
				max-height: 65px;
				padding-top: 6px;
				padding-bottom: 6px;
				cursor: pointer;

				&:first-child {
					padding-top: 20px;
				}
			}
		}

		&-space {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(100% - 140px);
			max-height: 100%;
			border-top-right-radius: 21px;
			border-bottom-right-radius: 21px;
			background: var(--white-100);

			@media (width <= 480px) {
				max-width: 480px;
			}
		}
	}

	&__btns-changePage {
		display: flex;
		flex-direction: column;
		margin-right: auto;
	}

	&__btn-changePage {
		display: flex;
		width: 14px;
		height: 14px;
		border: none;
		cursor: pointer;
	}

	&__icon-up {
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url("../../media/processes-icon.png") no-repeat center center;

		&:hover {
			background: url("../../media/processes-icon-active.png") no-repeat center center;
			transform: rotate(180deg);
		}
	}

	&__icon-down {
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url("../../media/processes-icon.png") no-repeat center center;
		transform: rotate(180deg);

		&:hover {
			background: url("../../media/processes-icon-active.png") no-repeat center center;
			transform: rotate(0deg);
		}
	}

	&__selectDetail {
		display: flex;
		width: 100%;
		max-width: 440px;
		height: 30px;
		padding-left: 12px;
		border: var(--border-width-thin) solid transparent;
		border-radius: var(--radius);
		font-size: 14px;
		line-height: 17px;
		background: var(--white-100);
		cursor: pointer;

		@media (width <= 480px) {
			max-width: 235px;
            margin-left: 5px;
		}

		&-content {
			display: flex;
			gap: 18px;
			align-items: center;
		}

		&-title {
			font-size: 21px;
			line-height: 26px;
		}

		&-icon {
			display: inline-block;
			width: 19px;
			height: 12px;
			background: url("../../media/angle-down-black.svg");
		}
	}

	&__camera {
		display: flex;
		flex-direction: column;
		width: 150px;
		height: 113px;
		background: var(--white-100);

		&-container{
			display: flex;
			flex: 1;
		}

		&-item{
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover{
				background: #F0E4ED !important;
			}
		}

		&-image{
			width: 22px;
			height: 22px;
		}
	}

    &__params{
		@media (width <= 480px) {
			display: none;
		}
	}

	&__params-mobile{
		display: none;

		@media (width <= 480px) {
			display: block;
			width: 33px;
			height: 33px;
		}
	}

	&__btn {
		width: 100%;
		max-width: 281px;
		height: 46px;
		border: none;
		border-radius: 15px;
		font-size: 21px;
		line-height: 26px;
		background: #f0e4ed;
		cursor: pointer;
		transition: all var(--transition);

		&:hover {
			color: var(--white-100);
			background: var(--purple-400);
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_variables{
			display: flex;
			flex-direction: column;
			padding-top: 10px;
			color: #731d5c;

			&_second{
				padding-top: 5px;
			}

			@media (width <= 480px) {
				max-width: 330px;
				margin-right: 15px;
				margin-left: 15px;
			}
		}

		@media (width <= 480px) {
			flex-direction: column;
			width: 100%;
			min-width: 380px;
		}
	}

	&__colors {
		display: flex;
		flex-wrap: wrap;
		gap: 18px;
		align-items: center;
		padding-top: 6px;
		padding-bottom: 5px;
		color: var(--dark-1000);

		@media (width <= 480px) {
			display: none;
		}

		.color-item {
			display: flex;
			gap: 5px;
			align-items: center;
			height: 30px;
			padding: 5px 10px;
			border-radius: 11px;
			background: var(--white-100);

			.text{
				font-size: 15px;
			}

			.color {
				width: 17px;
				height: 17px;
				margin: 5px 0;
			}
		}
	}

	&__colors-mobile{
		display: none;

		@media (width <= 480px) {
			display: flex;
			gap: 18px;
			align-items: center;
			padding-top: 6px;
			padding-bottom: 5px;
			color: var(--dark-1000);
		}

		.color-item-mobile {
			display: flex;
			align-items: center;
            max-width: 60px;
			height: 30px;
			padding: 5px;
			border-radius: 11px;
			background: var(--white-100);

			&_text{
				padding-left: 3px;
				font-size: 12px;
			}

			&_color {
				width: 13px;
				height: 13px;
				margin: 5px 0;
			}
		}
	}

	&__workspace-chossproc {
		display: none;

		&_active {
			display: flex;
			min-width: 621px;
			height: 543px;
			box-shadow: var(--container-shadow);

			@media (height >= 800px) {
			  height: 100%;
			}

			@media (width <= 480px) {
				width: 100%;
				min-width: 330px;
			}
		}
	}
}

.img-with-border {
	border-radius: 0 19px 0 0;
}

.active-process {
	background-color: #f7f1f5;
}

.chossenProcesses__btn {
	position: absolute;
	top: 3px;
	right: 3px;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 45px;
	background: var(--purple-50);
	cursor: pointer;

	&::before {
		display: inline-block;
		width: 23px;
		height: 19px;
		background: url("../../media/chossProc.png");
		content: "";
	}
}

.loaderCenter{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}