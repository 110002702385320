@import "../../scss/variables";

.control-button {
	padding: 3px;

	&-main {
		height: 32px;
		border-radius: 3px;

		&.single {
			width: 33px;
		}

		&::after {
			left: 17px
		}
	}

	.icon-button-arrow-ver {
		&.show::after {
			transform: scaleY(-1) translate(-50%, 50%);
		}
	}

	&-items {
		position: absolute;
		display: flex;
		padding: 3px;
		background: $color_white;
	}

	&-item, &-item.active {
		width: 32px;
		height: 32px;
		margin-right: 3px;
		border: 1px solid $color_icon;
		border-radius: 3px;
	}
}