@import "../../scss/variables";

.top-panel {
	position: absolute;
	top: $headerHeight;
	left: $panelWidth;
	display: flex;
	width: calc(100% - $panelWidth * 2);
	padding: 5px 10px;
	transition: all 0.4s;

	&.left-hidden {
		left: 50px;
		width: calc(100% - $panelWidth - $panelHiddenWidth);
	}

	&.right-hidden {
		width: calc(100% - $panelWidth - $panelHiddenWidth);
	}

	&.left-hidden.right-hidden {
		width: calc(100% - $panelHiddenWidth * 2);
	}
}