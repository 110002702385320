.warning-modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
    min-height: 251px;
    font-size: 20px;

    &-img {
      width: 88px;
      height: 70px;
      margin-top: 49px;
      margin-bottom: 23px;
      background: url("../../../../../media/ahtuung.png");
    }

    &-text {
      margin: 30px 30px 40px;
      font-size: 20px;
      text-align: center;
    }

    &-btns {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
