@import "/src/styles/mixins.scss";

.mainPage {
  padding-block: 60px;
  min-height: 100%;
  background: url("../../media/living-room-interior.png") no-repeat center;
  background-size: cover;

  .sale-info {
    margin-top: 90px;

    &__title {
      text-align: center;
    }
  }

  &__container {
    width: 100%;
    max-width: 1570px;
    padding-inline: 15px;
    margin-inline: auto;
  }

  &__title {
    color: var(--white-100);
    font-weight: var(--bold-weight);
    font-size: 24px;
    line-height: 93%;
    text-align: center;
    text-transform: uppercase;

    @include for-desktop-up {
      font-size: 30px;
    }
  }

  &__block-type {
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: center;
    margin-top: 70px;
  }

  &__iconEffect{
    filter: drop-shadow(0 4px 37.4px #9A04047C);
  }

  &__icon{
    width: 100%;
    height: 100%;
    object-fit: cover;

    &_youtube{
      width: 33.5px;
      height: 23.5px;
    }

    &_questions{
      width: 26px;
      height: 25px;
    }
  }
}
