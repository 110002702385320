$filter-sidebar-offset: 20px;
$filter-sidebar-width: 500px;

.filter-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: $filter-sidebar-width;
  height: 100%;
  background: var(--gray-50);
  transform: translateX($filter-sidebar-width);
  transition: transform var(--transition);

  &--opened {
    transform: translateX(0);
    transition: transform var(--transition);
  }

  &_header {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding: $filter-sidebar-offset;

    &-title {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    &-icon{
      width: 20px;
      height: 20px;
      background: url("../../../media/filter.png") no-repeat center center;
    }
  }

  &_content {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding-inline: $filter-sidebar-offset;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      background-color: var(--white-100);
      cursor: pointer;
      padding-block: 20px;

      &-angle {
        width: 15px;
        height: 9px;
        background-image: url('../../../media/angle.png');
        transition: transform var(--transition);
      }

      &-angle-open {
        transform: rotate(180deg);
        transition: transform var(--transition);
      }
    }

    &-materials{
      display: grid;
      grid-template-rows: 0fr;
      background-color: var(--white-100);
      transition: grid-template-rows var(--transition), padding-top var(--transition);

      &-list {
        overflow: hidden;
      }

      &--opened {
        grid-template-rows: 1fr;
        transition: grid-template-rows var(--transition), padding-top var(--transition);
      }

      &-item{
        display: flex;
        gap: 15px;
        padding: 10px 5px;

        &:not(:last-child) {
          border-bottom: 5px solid var(--gray-50);
        }
      }
    }
  }

  &-actions {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: $filter-sidebar-offset;
  }
}