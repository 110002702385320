$footerHeight: 50px;
$headerHeight: 50px;

$color_main: #a14389;
$color_black: #191616;
$color_white: #fff;
$color_lite_gray: #F9F9F9;
$color_gray: #424141;
$color_red: #d52262;
$color_green: #07a132;
$color_green_t: rgba(7,161,50, .2);
$color_gray_t: rgba(65,65,65, .2);
$color_icon: #A8A7A8;

$panelWidth: 300px;
$panelHiddenWidth: 35px;