@import "/src/styles/mixins.scss";
@import "/src/styles/variables.scss";

.main {
  display: flex;
  height: calc(100% - var(--header-height));
}

.layout-project {
  padding-inline: $page-offset;
  display: flex;
  flex-direction: column;
  width: 100%;
}
