@import "../../../../../styles/mixins.scss";

.tableItem {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  margin: 3px 0;
  padding-left: 26px;
  background: var(--white-100);

  &:hover{
    background: #e3d8e2;
  }

  &__inputs-container {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 628px;
    margin-right: 2px;
    padding-left: 23px;

    @include for-desktop-up {
      gap: 0;
      max-width: 775px;
    }
  }

  &__input {
    width: 61px;
    height: 26px;
    border: none;
    border-radius: var(--radius);
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    background-color: var(--form-field-bg);
    outline: none;

    &:focus {
      background: #f0e4ed;
    }

    &:disabled{
      background: #ecd3e0;
    }

    &-name {
      width: 100%;
      max-width: 118px;
      border: none;
      border-radius: var(--radius);
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      background: #f7f7f7;
      outline: none;

      &:focus {
        background: #f0e4ed;
      }

      @include for-desktop-up {
        max-width: 171px;
      }
    }

    @include for-desktop-up {
      width: 100%;
      max-width: 99px;
    }
  }

  &__number {
    width: 100%;
    max-width: 19px;
    text-align: center;
  }

  &__texture {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 78px;
  }

  &__delete {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 170px;
  }

  &__name {
    width: 100%;
    max-width: 171px;
    text-align: center;
  }

  &__input-icon {
    width: 100%;
    min-width: 35px;
    max-width: 65px;

    @include for-desktop-up {
      width: 100%;
      max-width: 69px;
    }
  }
}