.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  border: var(--border-width-medium) solid var(--purple-400);
  border-radius: 6px;
  cursor: pointer;

  &_active {
    border: none;
    background: var(--purple-400);
  }

  &__icon {
    display: none;

    &_active {
      display: inline-block;
      width: 16px;
      height: 100%;
      background: url("../../../../../media/checkbox.svg") no-repeat center
        center;
    }
  }
}

.disabled {
  opacity: 0.5;
}