.submitModalFurniture{
    display: flex;
    flex-direction: column;
	width: 470px;
	min-height: 200px;
    text-align: center;

  &__body {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-inline: var(--modal-indent);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
}