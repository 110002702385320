.addDetail {
  display: flex;
  flex-direction: column;
  width: 554px;
  max-height: 100vh;


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    font-size: 20px;
    line-height: 24px;

  }

  &__body {
    display: flex;
    flex-flow: column wrap;
    gap: 15px;
    width: 100%;
    padding: 25px 26px 24px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: 235px;
      margin-top: 21px;

      &-label {
        font-size: 14px;
        line-height: 17px;

      }

      &-input {
        display: flex;
        gap: 10px;
        align-items: center;
        height: 49px;
        padding: 0 18px;
        border: var(--border-width-thin) solid var(--dark-1000);
        border-radius: var(--form-field-radius);
        line-height: 18px;
      }
    }
  }

  &__input {
    width: 100%;
    height: 25px;
    border: none;
    line-height: 20px;
    outline: none;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__select {
    width: 100%;
    height: 40px;
    padding-right: 15px;
    padding-left: 18px;
    border: transparent;
    line-height: 20px;
    background-color: var(--form-field-bg);
    outline: none !important;
  }

  &__actions{
    display: flex;
    gap: 19px;
    justify-content: flex-end;

    &-check{
      min-width: 20px;
      margin-top: 8px;
      margin-left: 10px;
    }
  }

  &__checkbox{
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &_active{
      background: var(--purple-400);
    }
  }
}

