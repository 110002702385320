.logo {
	&__list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 34px;
		border: 0.1rem solid #e6e6e6;
		border-radius: 5rem;
		overflow: hidden;

		.logo__item:nth-child(2) {
			border-right: 0.1rem solid #e6e6e6;
			border-left: 0.1rem solid #e6e6e6;
		}
	}

	&__item {
		position: relative;
		transform: skewX(14deg);

		&::before,
		&::after {
			position: absolute;
			z-index: 0;
			transition:
				opacity var(--transition),
				visibility var(--transition);
			content: "";
			inset: 0;
		}

		&::after {
			visibility: hidden;
			opacity: 0;
		}

      &--master {
        .logo__text {
          font-weight: 600;
        }
      }

		&--main,
		&--master {
			&::before {
				background-image: linear-gradient(108.17deg, var(--white-100) 0.01%, #f0f0f0 95.08%);
			}

			&::after {
				background-image: linear-gradient(92.74deg, #bfbfbf -1.47%, #faf4f4 51.63%, #bfbfbf 111.15%);
			}

			@media (hover) {
				&:hover {
					&::before {
						visibility: hidden;
						opacity: 0;
						transition:
							opacity var(--transition),
							visibility var(--transition);
					}

					&::after {
						visibility: visible;
						opacity: 1;
						transition:
							opacity var(--transition),
							visibility var(--transition);
					}
				}
			}
		}

		&--personal {
			&::before {
				background-image: linear-gradient(90.1deg, #661741 0.08%, #be5296 47.72%, #63153e 96.36%);
			}

			&::after {
				background-image: linear-gradient(90deg, #661741 0.08%, #be5296 47.72%, #63153e 96.36%);
			}
		}
	}

	&__link,
	&__text {
		display: flex;
		text-transform: uppercase;
	}

	&__link {
		position: relative;
		z-index: 1;
		gap: 0.3rem;
		align-items: center;
		justify-content: center;
		min-height: 3.2rem;
		padding: 0 15px;
		transform: skewX(-14deg);
		cursor: pointer;
	}

	&__text {
		color: var(--dark-900);
		font-weight: var(--medium-weight);
		font-size: 12px;
		line-height: 100%;
		white-space: nowrap;

		@media (width <= 480px) {
			display: none;
		}
	}

	&__icon {
		display: flex;
		max-width: 2.2rem;
	}
}