.btnCross {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 23px;
  border: none;
  border-radius: 5px;
  background: var(--purple-400);
  cursor: pointer;
}

.icon-close {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: url("/media/small-cross.svg") no-repeat center center;
}
