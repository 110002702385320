.block {
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		padding-bottom: 5px;
		padding-left: 8px;
		align-items: center;

		&-title {
			flex-grow: 1;
			font-size: 14px;
			font-weight: 600;

		}

		&-control {
			margin-left: 10px;
			&.icon-button-arrow-ver:after {
				transform: scaleY(-1) translate(-50%, 50%);
			}
			&.show.icon-button-arrow-ver:after {
				transform: scaleY(1) translate(-50%, -50%);
			}
		}
	}

	&__body {
		border-left: 1px solid #A8A7A8;
		margin-left: 8px;
		padding-left: 3px;
		display: flex;
		flex-direction: column;
	}
}