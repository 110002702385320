.modal-calc-project {
  width: 100%;
  max-width: 850px;
  height: auto;
  max-height: 335px;
  font-weight: var(--medium-weight);


  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 22px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;

    &-table{
      width: 100%;
      height: 100%;
      background-color: #F3F3F3;

      &-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 47px;
        padding-right: 14px;
        padding-left: 14px;
        font-size: 14px;
      }

      &-item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding-right: 14px;
        padding-left: 14px;
        border: var(--border-width-thin) solid #DADADA;
        font-size: 14px;

        &-text{
          font-size: 14px;
        }
      }
    }

    &-detail{
      display: flex;
      justify-content: flex-end;
      color: var(--purple-400);

      &-text{
        margin-right: 15px;
        margin-left: 5px;
        font-size: 15px;

      }
    }
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;
  }
}

.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
  width: 1px;
  background: #cccccc;
}