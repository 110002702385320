@import "/src/styles/mixins.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1024px;
  height: var(--header-height);
  padding: 0 30px;
  background: var(--white-100);

  @media (width <= 480px) {
    width: 100%;
    min-width: 380px;

  }

  &__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;

    @media (width <= 480px) {
      align-items: flex-end;
      width: 2%;
    }
  }

  &__registration{
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &-text{
      margin-right: 10px;
      margin-left: 10px;
      line-height: 21px;
      cursor: pointer;
    }
  }

  &__links{
    display: flex;
    align-items: center;

    @media (width <= 480px) {
      display: none;
    }

    &-link{
      margin-top: 5px;
      margin-right: 20px;
    }
  }

  &__active {
    height: 100vh;
  }
}

.link{
  padding: 16px 24px;

  &:last-child{
    padding-right: 5px;
  }
}

.active{
  background: var(--purple-50);
}

.kronasLinks{
  display: flex;
  margin-right: 25px;
  border: var(--border-width-thin) solid #E6E6E6;
  border-radius: 50px;
  stroke-width: 0.5px;
}

.link__logo-img{
  height: 100%;
}

.link__kronas{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 158px;
  background: linear-gradient(109deg, #FFFFFF 0%, #F0F0F0 100%);
  transform: skewX(17deg);

  &:hover{
    background: linear-gradient(93deg, #BFBFBF -1.47%, #FAF4F4 51.63%, #BFBFBF 111.15%);
  }

  &-img{
    transform: skewX(-17deg);
  }
}

.link__profilePage{
  display: flex;
  gap: 7px;
  align-items: center;
  margin-left: 12px;
  padding-right: 16px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: linear-gradient(108deg, #FFFFFF 0%, #F0F0F0 92.31%);
}

.link__profilePage-text{
  color: var(--dark-900);
  font-weight: var(--medium-weight);
  font-size: 11px;
}

.btn_contact{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rotate{
  transform: rotate(180deg);
  transition: var(--transition) ease;
}

.rotateBack{
  transform: rotate(0deg);
  transition: var(--transition) ease;
}

.contact_tooltip{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 212px;
  padding: 14px 17px;
  border-radius: var(--radius);

  &__container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: var(--border-width-thin) solid #00000014;

    &:first-child{
      padding-top: 0;
    }

    &:last-child{
      padding-bottom: 0;
      border: none;
    }

    &_support{
      line-height: 30px;
      cursor: pointer;
    }

    &_questions{
      font-size: 14px;
    }

    &-grey-line{
      height: 1px;
      border-top: var(--border-width-thin) solid #00000014;
    }
  }

  &__time{
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    &-item{
      margin-bottom: 5px;
      font-weight: var(--medium-weight);
      font-size: 12px;
    }
  }

  &_call{
    display: flex;
    gap: 5px;
    color: var(--purple-400);
  }
}

.training-video{
  line-height: 40px;

  &-link{
    color: var(--purple-400);
  }
}