.modal-add-details_error {
  width: 75%;
  height: auto;
  max-height: 95%;



  &__title{
    line-height: 19.2px;
  }

  &__info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 55%;
    max-width: 565px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--modal-header-bg);
    box-shadow: var(--box-shadow);
    padding-inline: var(--modal-indent);

    &-item {
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;

      &_active {
        height: 80%;
        border-radius: 7px;
        color: var(--white-100);
        background: var(--purple-400);
      }
    }
  }

  &__data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    height: 34px;
    margin: 0 auto;
  }

  &__body{
    width: 100%;
    height: auto;
    max-height: 258px;
    overflow-y: auto;

    &-no-parts-found{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 560px;
      height: 100px;

    }
  }

  &_loader{
    width: 100%;
    height: 200px;
  }

  &__body-item{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    line-height: 19px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
    padding-inline: var(--modal-indent);
  }

  &__body-itemError{
    display: flex;
    gap: 10px;
    align-items: center;
    height: 28px;
    padding-left: 43px;
    color: var(--error);
    font-size: 14px;
    background: #F5EDED;
  }

  &__data-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    margin-right: 10px;
  }

  &__checkbox{
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &_active{
      background: var(--purple-400);
    }
  }

  &__number{
    width: 15px;
    text-align: center;
  }

  &__img{
    width: 33px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__name{
    width: 100%;
    max-width: 100px;
    margin-left: 10px;
    white-space: nowrap;
  }

  &__texture{
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);

    &_active{
      background: var(--purple-400);
    }
  }

  &__edges{
    position: relative;
    width: 32px;
    height: 31px;
    border: var(--border-width-thin) solid var(--purple-400);
    border-radius: var(--radius);

    &-top{
      position: absolute;
      top: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(180deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-right{
      position: absolute;
      top: 10px;
      right: 1px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(270deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-bottom{
      position: absolute;
      bottom: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-left{
      position: absolute;
      top: 10px;
      left: 2px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(90deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }
  }

  &__wraperBtn{
    display: flex;
    width: 100%;
    padding-top: 15px;

    &__btns{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__btnOne{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__btn-delete{
    margin-left: 90px;
  }

  &__btn-delete-error{
    margin-left: 53px;
  }

  &__icon-error{
    margin-left: 20px;
  }
}


.modal-add-furniture_error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65%;
  height: auto;
  max-height: 85%;
  padding: 16px 25px 30px;



  &__title{
    padding: var(--modal-indent);
  }

  &__info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    max-width: 565px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    padding: 0 23px;
    background-color: var(--white-100);
    box-shadow: var(--box-shadow);

    &-item {
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;

      &_active {
        height: 80%;
        border-radius: 7px;
        color: var(--white-100);
        background: var(--purple-400);
      }
    }
  }

  &__data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    height: 34px;
    margin: 0 auto;
  }

  &__body{
    width: 100%;
    height: auto;
    max-height: 174px;
    overflow-y: auto;

    &-no-parts-found{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 560px;
      height: 100px;

    }
  }

  &_loader{
    width: 100%;
    height: 200px;
  }

  &__body-item{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px 17px 9px 24px;
    line-height: 19px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__body-itemError{
    display: flex;
    align-items: center;
    height: 28px;
    padding-left: 43px;
    color: var(--error);
    font-size: 14px;
    background: #F5EDED;
  }

  &__data-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__checkbox{
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &_active{
      background: var(--purple-400);
    }
  }

  &__number{
    width: 15px;
    margin-left: 10px;
    text-align: center;
  }

  &__img{
    width: 33px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__name{
    width: 100%;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__texture{
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);

    &_active{
      background: var(--purple-400);
    }
  }

  &__edges{
    position: relative;
    width: 32px;
    height: 31px;
    border: var(--border-width-thin) solid var(--purple-400);
    border-radius: var(--radius);

    &-top{
      position: absolute;
      top: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(180deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-right{
      position: absolute;
      top: 10px;
      right: 1px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(270deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-bottom{
      position: absolute;
      bottom: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-left{
      position: absolute;
      top: 10px;
      left: 2px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(90deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }
  }

  &__wraperBtn{
    display: flex;
    width: 100%;
    padding-top: 37px;

    &__btns{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__btnOne{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__btn-delete{
    margin-left: 90px;
  }

  &__btn-delete-error{
    margin-left: 53px;
  }

  &__icon-error{
    margin-left: 20px;
  }
}

.img22{
  position: unset;
  right: 5%;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.itemError{
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 28px;
  padding-left: 43px;
  color: var(--error);
  font-size: 14px;
  background: #F5EDED;
}