.nomenclature-data {
  margin-bottom: 3rem;
  border-radius: 0.4rem;
  background-color: var(--white-100);
  box-shadow: 0 0.1rem 0.2rem #0000001a;
  padding-block: 3rem;

  .uah {
    color: #6A7178FF;
  }

  .details-table {
    min-width: 65rem;
    max-height: calc(100vh - 18rem);
    overflow: auto;

  }

  &__title {
    margin-bottom: 1.5rem;
    margin-left: 2rem;
  }

  &__price {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    padding-inline: 3rem;

    &-text {
      font-weight: var(--bold-weight);
      font-size: inherit;
      text-align: right;
    }

    &-total {
      color: #A04382FF;
    }
  }

  &__sum {
    padding-right: 3rem;

    &-value {
      text-transform: uppercase;
    }
  }
}