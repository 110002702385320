.deleteProcesses {
	position: relative;
	width: 100%;
	height: 100%;
	background: #FBFBFB;
	box-shadow: var(--container-shadow);
	opacity: 1;
	transition: all var(--transition) ease;

	&__header {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		width: 563px;
		height: 45px;
		margin-top: 3px;
		padding: 11px 14px;
		background: var(--purple-50);
	}

	&__datas {
		position: relative;
		height: calc(100% - 48px);
		margin-right: 3px;
		overflow: hidden auto;
	}

	&__sort {
		display: flex;
		justify-content: space-between;
		padding: 20px 9px;

		&-item {
			padding: 7.5px 8.5px;
			border-radius: var(--radius);
			font-size: 15px;
			line-height: 19px;
			background: #F0E4ED;
			cursor: pointer;
		}
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 9px 0 16px 9px;

		&_wrapper{
			display: flex;
			gap: 10px;
			align-items: center;
		}

		&_delAll{
			display: flex;
			gap: 15px;
			align-items: center;
			margin-right: 20px;

			&_svgSize {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}

			&_arrow-rotate{
				transition: var(--transition) ease;
			}

			&_arrow-down{
				transform: rotate(180deg);
				transition: var(--transition) ease;
			}
		}
	}

	&__title-icon {
		display: inline-block;
		width: 22px;
		height: 22px;
		background: url('../../../media/icon-notification.png');
	}

	&__title-text {
		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 621px;
		height: 71px;
		margin-right: 5px;
		margin-bottom: 4px;
		padding: 5px 10px 0 14px;
		background: var(--white-100);
        box-shadow: var(--box-shadow);
		cursor: pointer;

		&:hover{
			background: #F0E4ED;
		}

		.deleteProcesses__item + & {
			height: 41px;
		}

		&_error {
			background: #FF000026;
		}

		&_tooltip-error{
			z-index: 9999;
			width: max-content;
			height: 60px;
			max-height: max-content;
			padding: 0 !important;

			&_body{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				max-width: 621px;
				height: 100%;
				padding-right: 15px;
				padding-left: 15px;
				border-radius: var(--radius);
				background: #E8C2C2FF !important;
			}
		}

		&_deleted{
			opacity: .3;
		}

		&_deleted::after{
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100%;
			height: 0;
			border-bottom: 5px solid #a1438970;
			color:  var(--purple-400);
			transform: rotate(0deg);
			content: "";
		}
	}

	&__number {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		width: 15px;
		height: 54px;
		margin-right: 15px;
		padding-top: 3px;
		padding-bottom: 5px;

		.deleteProcesses__item + .deleteProcesses__item> & {
			justify-content: center;
			padding-top: 0;
		}
	}

	&__item-number1 {
		display: none;

		.deleteProcesses__title + .deleteProcesses__item & {
			display: block;
		}
	}

	&__content {
		display: flex;
		gap: 5px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 471px;

	}

	&__item-header-title {
		min-width: 75px;
		font-size: 14px;
		text-align: center;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__comment {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		height: 22px;
		border: none;
		background: url('../../../media/comment.png');
		cursor: pointer;
	}

	&__comment-icon {
		display: inline-block;
		width: 22px;
		height: 22px;

	}

	&__item-datas {
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: center;
		width: 100%;
		height: 54px;
		padding-bottom: 5px;
		overflow-x: auto;
	}

	&__item-header {
		display: none;
		gap: 6px;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.deleteProcesses__title + .deleteProcesses__item & {
			display: flex;
		}
	}

	&__item-header-groove {
		display: flex;
		gap: 6px;
		align-items: center;
		width: 100%;
	}

	&__body {
		height: 100%;
	}

	&__item-body {
		display: flex;
		gap: 6px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__data {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 75px;
		height: 21px;
		font-size: 14px;
		background: #F1F1F1;

		&_error {
			background: #FFE4E4;
			cursor: pointer;
		}

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__btns {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		height: 54px;
		margin-right: 4px;
		margin-left: 8px;
		padding-bottom: 10px;

		.deleteProcesses__item + .deleteProcesses__item > &{
			justify-content: center;
			padding-bottom: 5px;
		}
 	}

	&__btns-container {
		display: flex;
		gap: 4px;
	}

	&__btn-edit {
		display: flex;
		width: 22px;
		height: 22px;
		border: none;
		background: url("../../../media/btn-edit.png");
		cursor: pointer;
	}

	&__btn-delete {
		display: flex;
		width: 22px;
		height: 22px;
		border: none;
		background: url("../../../media/btn-delete.png");
		cursor: pointer;
	}

	&__comment-background {
		position: absolute;
		top: 48px;
		z-index: 100000;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(100% - 48px);
		background: #5B5B5B3F;
		backdrop-filter: var(--backdrop);
	}

	&__comment-container {
		width: 509px;
		height: 275px;
		padding: 15px 19px;
		border-radius: 12px;
		background: var(--white-100);
		box-shadow: 0 6.679px 119px 0 #9595953D;
	}

	&__comment-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 14px;
	}

	&__comment-body {
		display: flex;
		flex-shrink: 0;
		width: 471px;
		height: 198px;
		padding: 13px;
		border: var(--border-width-thin) solid var(--dark-1000);
		border-radius: var(--radius);
		word-break: break-all;
	}

	&__dontProc {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-size: 28px;

	}

	&__circle-style {
		justify-content: space-around;
	}
}

div.deleteProcesses__item-datas::-webkit-scrollbar {
	width: 5px;
	height: 7px;
	margin-top: 3px;
}

div.deleteProcesses__datas::-webkit-scrollbar {
	width: 7px;
}

.chosProc-icon {
	display: inline-block;
	min-width: 75px;
	height: 21px;
	background-repeat: no-repeat;
	background-position: center;

	&__angle-left-bottom {
		background-image: url('../../../media/angle-left-bottom.png');
	}

	&__angle-left-top {
		background-image: url('../../../media/angle-left-top.png');
	}

	&__angle-right-bottom {
		background-image: url('../../../media/angle-right-bottom.png');
	}

	&__angle-right-top {
		background-image: url('../../../media/angle-right-top.png');
	}

	&__back-platform {
		background-image: url('../../../media/back-platform.png');
	}

	&__face-platform {
		background-image: url('../../../media/face-platform.png');
	}

	&__right-platform {
		background-image: url('../../../media/right-platform.png');
	}

	&__left-platform {
		background-image: url('../../../media/left-platform.png');
	}

	&__top-platform {
		background-image: url('../../../media/top-platform.png');
	}

	&__bottom-platform {
		background-image: url('../../../media/bottom-platform.png');
	}

	&__vert-proc {
		background-image: url('../../../media/vert-proc.png');

	}

	&__horiz-proc {
		background-image: url('../../../media/horiz-proc.png');

	}
}

.millClassForItem {
	height: 71px !important;
}

.millClassForHeader {
	display: flex !important;
}

