.resource-links {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 70px;

  &__link-block {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 274px;
    height: 55px;
    padding: 15px 25px;
    border-radius: 36px;
    background-color: var(--white-100);
    box-shadow: 0 1.736px 1.736px 0 #FFFFFF26 inset, 0 0 26.047px 0 #FFFFFF0C inset, 0 4px 43.6px 0 #00000087;
  }

  &__icon {
    display: flex;
  }

  &__text {
    font-size: 18px;
    white-space: nowrap;
    text-align: center;
  }
}