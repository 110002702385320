@import "../../../styles/mixins.scss";

.deleteMaterial {
  &__text {
    padding: var(--modal-indent);
    font-size: 20px;
  }

  &__body {
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 250px);
    background: var(--white-100);
  }

  &__material-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  &__chosen-material {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 43px;
    border: none;
    border-radius: 50%;
    background-color: var(--purple-400);
    cursor: pointer;
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 37px;
  }

  &__icon {
    display: inline-block;
    width: 37px;
    height: 35px;
    background: url("../../../media/texture.svg");
  }

  &__details-header {
    display: flex;
    width: 100%;
    padding: 10px 20px;
  }

  &__details-list {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
  }

  &__detail {
    display: flex;
    padding: 10px 20px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 51px;
    margin-right: 26px;
    text-align: center;
  }

  &__name {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 432px;

    @include for-modal-up {
      max-width: 206px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 37px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
}