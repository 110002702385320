@import "../../../../styles/mixins.scss";

.addMaterial {
  &__body {
    &-block {
      display: flex;
      justify-content: center;
    }

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1000px;
      background: var(--gray-50);

      &-item {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;
        padding: 12px;
        background: var(--white-100);
        box-shadow: 0 6.483px 115.520px 0 #9595953D;
      }

      &-item > span {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .addMaterial__body-list-item > span:nth-child(2) {
        flex: 5;
        margin-right: 20px;
      }

      &-item:hover {
        background-color: var(--purple-50);
        cursor: pointer;
      }
    }
  }
}