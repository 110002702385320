.edit-count-modal {
  width: 423px;



  &__title {
    font-size: 18px;
    line-height: 22px;
    text-align: center;

  }

  &__body {
    padding: 20px 15px;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
  }
}
