.hardwareTab{
  display: flex;
  gap: 4px;
  justify-content: space-between;
  background-color: var(--gray-50);

  &_oneCol{
    width: 100%;

    &-container{
      display: flex;
      gap: 50px;
      justify-content: flex-end;
      width: 100%;
    }

    &-name{
      display: flex;
      justify-content: flex-end;
      width: 230px;
    }

    &_sum{
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      height: 100px;
      padding: 10px 10px 10px 0;
      line-height: 19px;
      text-align: left;

      &_sub{
        display: flex;
        justify-content: flex-end;
        width: 200px;
      }
    }

    &__header{
      display: flex;
      flex-direction: column;

      &_wrap{
        display: flex;
        justify-content: space-between;
        height: 64px;
        padding: 20px 10px 19px 0;

        &__checkbox{
          min-width: 20px;
          height: 20px;
          margin-left: 20px;
          border: var(--border-width-medium) solid var(--purple-400);
          border-radius: var(--radius);
          cursor: pointer;

          &_active{
            background: var(--purple-400);
          }
        }

        &_nameCol{
          margin-left: 20px;
        }

        &_delete{
          display: flex;
          gap: 15px;
          align-items: center;

          span{
            color: var(--gray-300);
            font-size: 14px;
          }
        }
      }

      &_sub{
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background-color: var(--white-100);

        &_text{
          color: var(--gray-300);
          font-size: 14px;
        }

        &_name{
          width: 45%;
        }

        &_first{
          min-width: 20px;
        }

        div:nth-child(2){
          width: 3%;
        }

        div:nth-child(3){
          width: 47%;
        }

        div:nth-child(4){
          width: 9%;
        }

        div:nth-child(5){
          width: 5%;
        }

        div:nth-child(6){
          width: 5%;
        }

        div:nth-child(7){
          width: 5%;
        }

        div:nth-child(8){
          width: 25px;
        }

      }

      &_loader{
        height: calc(100vh - 430px);
      }

      &_empty{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        font-size: 20px;
        line-height: 22px;

      }

      &_listItem{
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        margin: 0 auto;
        padding-right: 10px;
        padding-left: 20px;
        background-color: var(--white-100);


        &__checkbox{
          min-width: 20px;
          height: 20px;
          border: var(--border-width-medium) solid var(--purple-400);
          border-radius: var(--radius);
          cursor: pointer;

          &_active{
            background: var(--purple-400);
          }
        }

        &_name{
          width: 30%;
        }

        &_text{
          display: flex;
          align-items: center;
          width: 10%;
          color: var(--gray-300);
          font-size: 14px;

          &_subText{
            overflow: hidden;
            color: var(--gray-300);
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &_link{
              line-height: 19px;
            }

            &_link:hover{
              color: var(--purple-400);
            }

            &_link:visited{
              color: #470B38;
            }
          }

          &_img{
            width: 34px;
            height: 31px;
            border-radius: var(--radius);
            box-shadow: 0 6.679px 119px #9595953D;
          }
        }

        &_text:nth-child(2){
          width: 4%;
          margin-left: 20px;
        }

        &_text:nth-child(3){
          gap: 6px;
          width: 50%;
        }

        &_text:nth-child(5){
          width: 5%;
        }

        &_text:nth-child(6){
          width: 5.6%;
        }

        &_text:nth-child(7){
          width: 5%;
        }
      }
    }
  }
}

.flex{
  display: flex;
}