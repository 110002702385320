.modal-multiplicity-project {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: calc(100% - 40px);
  max-height: 100vh;


  &__header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 9px 22px;
    background: var(--modal-header-bg);
  }

  &__title {
    font-size: 18px;
    line-height: 22px;

  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    overflow-y: auto;
    background: #FAFAFA;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
  }

  &__checkbox-wrapper{
    margin: 0 32px;
  }

  &__isRotateTexture-text{
    width: 148px;
    margin-right: 90px;
    color: var(--dark-1000);
    text-align: start;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &_sub{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 85px;
      margin-bottom: 5px;
      padding: 15px 46px 15px 38px;
      background: var(--white-100);

      &__default-select {
        display: flex;
        align-items: center;
        width: 379px;
        max-width: 100%;
        height: 41px;
        padding-right: 15px;
        padding-left: 10px;
        border: var(--border-width-thin) solid transparent;
        line-height: 24px;
        background: #F8F8F8 url('../../../../media/selectTriangle.png') no-repeat calc(100% - 5px) calc(0.5em + 8px);
        cursor: pointer;
        appearance: none;

        &:focus {
          outline: none;
        }

        &:invalid{
          color: #451D5460;
        }
      }
    }

    &__item{
      margin-left: 24px;
    }
  }
}

.bg_active{
  background: #EFE3EC;
}

.img22{
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}