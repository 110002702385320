html,
body,
#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--gray-500);
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-color: var(--purple-400) #e0e0e0;
  scrollbar-width: thin;
  font-family: var(--font-family-primary);
}

ul,
ol {
  list-style: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

input, select {
  font-size: var(--font-size-small);
}