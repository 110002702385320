@import "../../../../../styles/mixins.scss";

.material {
  display: flex;
  align-items: center;

  @include for-modal-up {
    padding-right: 55px;
  }

  &__checkbox {
    margin-right: 30px;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 713px;

    @include for-modal-up {
      max-width: 283px;
    }
  }

  &__img {
    width: 47px;
    height: 27px;
    margin-right: 26px;
  }

  &__name {
    width: 100%;
    line-height: 19px;


    @include for-modal-up {
      max-width: 206px;
    }
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 729px;
    height: 37px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 145px;
    height: 40px;
    text-align: center;

    &-item {
      font-size: 14px;
      line-height: 17px;

    }

    &-item-second {
      line-height: 19px;

    }
  }
}
