.detailsTab {
  &__input {
    height: 26px;
    border: none;
    border-radius: var(--radius);
    background-color: var(--form-field-bg);
    padding-inline: 7px;

    &:focus {
      outline: var(--border-width-medium) solid var(--gray-500);
    }

    &:disabled {
      color: #999999FF;
      cursor: not-allowed;
    }
  }

  &__dnd {
    &.dragging {
      visibility: hidden;
      opacity: 0;
      transition: opacity var(--transition), visibility var(--transition);
    }

    &[draggable="true"] {
      cursor: grab;
    }
  }

  &__materials-tooltip{
    margin: 0 10px 10px;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    min-height: 34px;
    margin-bottom: 5px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
    padding-inline: 20px;

    &-item {
      font-size: 14px;
      line-height: 17px;


      &_active{
        min-height: 25px;
        border-radius: 7px;
        color: var(--white-100);
        background: var(--purple-400);
      }
    }
  }

  &__body-item{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    line-height: 19px;
    background-color: var(--white-100);
    box-shadow: var(--box-shadow);
    transition: var(--transition) background-color;

    &--selected {
      background-color: #c7439c52;
      transition: var(--transition) background-color;
    }

    &:not(.detailsTab__body-item--selected):hover {
      background-color: #f1e5ef;
      transition: var(--transition) background-color;
    }
  }

  &__data-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
  }

  &__data-input{
    width: 69px;

    &--edge {
      width: 35px;
    }
  }

  &__data-input_name{
    width: 273px;
  }

  &__handlers {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  &__handler {
    display: flex;
  }

  &__checkbox{
    display: inline-flex;
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &:focus-within {
      border-color: var(--gray-500);
    }

    &_active{
      background: var(--purple-400);
    }

    &_disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__number{
    width: 25px;
    text-align: center;
  }

  &__img{
    width: 50px;
    max-height: 50px;
  }

  &__name{
    width: 100%;
    max-width: 352px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__name-edit{
    width: 273px;
    max-width: 352px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__material-edit{
    width: 100%;
    max-width: 126px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__texture-edit{
    width: 6%;
    min-width: 80px;
    max-width: 352px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
  }

  &__products-edit{
    width: 100%;
    max-width: 273px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__texture{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);

    &_active{
      background: var(--purple-400);
    }
  }

  &__edges{
    position: relative;
    width: 32px;
    height: 31px;
    border: var(--border-width-thin) solid var(--purple-400);
    border-radius: var(--radius);

    &-group {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &-tooltip {
      &-wrapper {
        max-height: 400px;
        overflow-y: auto;
      }
    }

    &-edit{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
    }

    &-item {
      position: absolute;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url(../../../../media/tableEdge.svg);

      &--active {
        background: url(../../../../media/tableEdge-active.svg);
      }
    }

    &-top{
      top: 0.2px;
      left: 8.5px;
    }

    &-right{
      top: 10.5px;
      left: 19.7px;
      transform: rotate(90deg);
    }

    &-bottom{
      bottom: 0.2px;
      left: 9px;
      transform: rotate(180deg);
    }

    &-left{
      top: 10.8px;
      right: 19.2px;
      transform: rotate(270deg);
    }
  }

  &__icon-error {
    display: flex;
  }
}

.tooltip-multiplicity{
  &_header{
    height: 34px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    background: #F0E4ED;
  }

  &_body{
    display: flex;
    flex-direction: column;
    width: 345px;
    height: 443px;
    border-radius: var(--radius);

    &_wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 53px;
      margin-top: 6px;
      padding-left: 20px;

      &_sub{
        display: flex;
        width: 80%;
      }

      &_img{
        width: 38px;
        height: 38px;
        margin-left: 10px;
      }
    }
  }
}

.selectedM{
  background: #F0E4ED;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

#multiplicity-tooltip-0,
#multiplicity-tooltip-1,
#multiplicity-tooltip-2,
#multiplicity-tooltip-3,
#multiplicity-tooltip-4,
#multiplicity-tooltip-5,
#multiplicity-tooltip-6,
#multiplicity-tooltip-7,
#multiplicity-tooltip-8 {
  top: 25% !important;
}

.filter-icon{
  width: 9px;
  height: 9px;
  margin-left: 8px;
  background-image: url(../../../../media/filterIcon.svg);
}

.selected{
  background-color: #ea40bb;
}

@keyframes pulseWarning {
  0% {
    box-shadow: 0 0 0 0 #E7B832D1;
  }

  70% {
    box-shadow: 0 0 0 10px #E7B83200;
  }

  100% {
    box-shadow: 0 0 0 0 #E7B83200;
  }
}

.border_warning {
  border: solid 4px #E7B832D1;
  border-radius: var(--radius);
  animation: pulseWarning 2s infinite;
}