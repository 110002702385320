.submit-modal{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	max-width: 470px;
	min-height: 215px;


  &__body{
    display: flex;
    flex: 1;
    align-items: center;
    margin-inline: auto;
  }

  &__actions{
    display: flex;
    justify-content: space-between;
  }
}