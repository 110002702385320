.ahtungModal{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;

  &__img {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__body{
    flex: 1;
    padding: var(--modal-indent);
    text-align: center;

    &-decription{
      &-title{
        margin-bottom: 10px;
        color: var(--purple-400);
        font-size: 24px;
      }

      &-body_text{
        margin-top: 5px;
      }
    }
  }

  &__list{
    margin-top: 15px;
    margin-bottom: 25px;
    padding-left: 30px;

    &-item{
      margin-top: 10px;
      font-size: 15px;
      text-align: start;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
  }
}