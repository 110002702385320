.request-modal {
  &__content {
    width: 731px;
    min-height: auto;
    max-height: 90vh;
    overflow-y: auto;
    font-size: 14px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

  }

  &__subject {
    padding: var(--modal-indent);
    font-size: 14px;
    background-color: var(--white-100);
  }

  &__subject-title{
    font-size: 14px;
  }

  &__additions {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--gray-50);
  }

  &__subject-area {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: var(--form-field-radius);
    background-color: var(--form-field-bg);
    outline: none;
    resize: none;
  }

  &__subject-area-text {
    height: 204px;
  }

  &__subject-area-title {
    height: 58px;
  }

  &__files {
    display: flex;
    align-items: center;
    height: 50px;
    margin-right: 35px;
    cursor: pointer;
  }

  &__files-text{
    font-size: 14px;
  }

  &__files-input {
    display: none;
  }

  &__files-clip {
    display: inline-block;
    width: 20px;
    height: 17px;
    margin-right: 5px;
    background: url("../../../../media/paper-clip.svg") no-repeat right var(--white-100);
  }

  &__header-file {
    padding: 16px 24px 16px 23px;
    font-size: 14px;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    padding: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
    font-size: 14px;
    background: var(--white-100);
  }

  &__list-item-block {
    display: flex;
    align-items: center;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__list-item_img {
    width: 23px;
    height: 23px;
    margin: 0 10px 0 23px;
  }

  &__list-item_name {
    font-size: 14px;
  }

  &__list-item_trash {
    width: 15px;
    height: 15px;
    margin-right: 20px;
    background: url("../../../../media/trash-button.svg");
    cursor: pointer;
  }
}