.accordion {
  width: 100%;

  &__item {
    margin-bottom: 4px;
    border: var(--border-width-thin) solid var(--white-100);
    box-shadow: 5.1px 7.287px 19px 0 #93939311;
    cursor: pointer;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 14px;
    padding-left: 32px;
    background-color: var(--white-100);
  }

  &__content {
    padding-bottom: 21px;
    padding-left: 32px;
    background: #edeef1;
  }

  &__item.open &__title {
    background: #edeef1;
  }

  &__arrow {
    display: inline-block;
    width: 15px;
    height: 10px;
    margin-right: 20px;
    padding-right: 10px;
    background: url("../../../media/vector-arrow.svg") no-repeat right var(--white-100);
    transform: rotate(180deg);
  }

  &__arrow.open {
    display: inline-block;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 10px;
    background-color: #edeef1;
    transform: rotate(360deg);
    transition: var(--transition) ease;
  }
}
