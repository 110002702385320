.param {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: 50%;
	height: 85px;
	margin-bottom: 10px;
	padding: 13px 10.5px;
	font-size: 14px;
	background: var(--white-100);

	&_tooltip{
		position: absolute;
		z-index: 9999;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: auto;
		height: auto;
		margin-top: 60px;
		border-radius: var(--radius);
		background: var(--white-100);
		box-shadow: 0 5.457px 37px 0 #4E4E4E42;
		filter: drop-shadow(0 7.677px 120px #00000033);

		&-item-wrapper{
			padding: 6px;

			&__area{
				width: 280px;
				height: 100px;
				padding: 10px;
				border: none;
				background: var(--form-field-bg);
				outline: none;
				resize: none;
			}

			&_btns{
				display: flex;
				justify-content: space-between;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}

	&__label-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__label {
		margin-left: 5px;
		font-size: 14px;
		line-height: 17px;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__label2{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__label3{
		width: 30px;
	}

	&__checkbox-orig-container{
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	&__error{
		padding: 10px 15px;
	}

	&__default-select {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 100%;
		height: 41px;
		padding-right: 15px;
		padding-left: 10px;
		border: var(--border-width-thin) solid transparent;
		border-radius: var(--radius);
		line-height: 24px;
		background-color: var(--form-field-bg);
		background-image: url('../../../media/selectTriangle.png');
		background-repeat: no-repeat;
		background-position: calc(100% - 5px) calc(0.5em + 5px);
		appearance: none;

		&:focus {
			outline: none;
		}

		&:disabled{
			background: #F0E4ED;
		}
	}

	&__default-input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 41px;
		padding-right: 15px;
		padding-left: 18px;
		border-radius: var(--radius);
		background-color: var(--form-field-bg);

	}

	&__input {
		width: 100%;
		border: none;
		line-height: 24px;
		background: var(--form-field-bg);
		outline: none;
	}

	&__error-caption {
		position: absolute;
		margin-top: 50px;
		margin-left: -18px;
		color: #942222;
		font-size: 12px;
		line-height: 1.2em;
	}

	&__additional-checkbox {
		position: absolute;
		top: 10px;
	}

	&__additional-right {
		right: 25px;
	}
}

.paramIcon__center_x, .paramIcon__center_z {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 23px;
	height: 24px;
	margin-left: 5px;
	border: none;
	background: var(--purple-400);
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 21px;
		height: 15px;
		background: url("../../../media/long-x.svg") no-repeat;
		content: '';
	}
}

.paramIcon__center_y {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 22px;
	height: 24px;
	margin-left: 5px;
	border: none;
	background: var(--purple-400);
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 14.5px;
		height: 24px;
		background: url("../../../media/long-y.svg") no-repeat;
		content: '';
	}
}

.paramIcon__center {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 22px;
	height: 24px;
	margin-left: 5px;
	border: none;
	background: var(--purple-400);
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 20.5px;
		height: 16px;
		background: url("../../../media/long-x.svg") no-repeat;
		content: '';
	}
}

.paramIcon__formula {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 22px;
	height: 24px;
	border: none;
	background: var(--purple-400);
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 19.5px;
		height: 16px;
		background: url("../../../media/formulaIcon.svg") no-repeat center;
		content: '';
	}
}

.paramIcon__formulaEmpty {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 22px;
	height: 24px;
	border: none;
	background: #C5C5C5;
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 19.5px;
		height: 16px;
		background: url("../../../media/formulaIconEmpty.svg") no-repeat center;
		content: '';
	}
}

.paramIcon__allWidth {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 22px;
	height: 24px;
	margin-left: 5px;
	border: none;
	background: var(--purple-400);
	cursor: pointer;
	user-select: none;

	&::before {
		display: inline-block;
		width: 16.5px;
		height: 16px;
		background: url("../../../media/long.svg") no-repeat;
		content: '';
	}
}

.paramIcon__ext {
	position: absolute;
	z-index: -1;
	padding: 5px;
	opacity: 0;
}

input.paramIcon__ext + label {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 15px;
	height: 16px;
	margin-left: 8px;
	border: var(--border-width-medium) solid var(--purple-400);
	border-radius: 4px;
	cursor: pointer;
	user-select: none;
}

input.paramIcon__ext:checked + label {
	background: var(--purple-400);
}

input.paramIcon__ext:checked + label::before {
	display: inline-block;
	width: 12px;
	height: 13px;
	background: url("../../../media/checkbox.svg") no-repeat;
	content: '';
}

.directionLabel {
	position: relative;
	top: -368px;
	left: 315px;
	z-index: 3;
	display: flex;
	align-items: center;
	font-size: 10px;

	&__ext{
		position: relative;
		top: -315px;
		order: 1;
	}

	&__additionalCutter {
		position: relative;
		top: -180px;
		left: 273px;
		order: 2;
	}
}

.bg-error{
	background: #F0E4ED;
}

.groupHoles{
	width: 100%;
	padding-top: 5px;
	padding-bottom: 15px;
	padding-left: 26px;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--error);
	}

	70% {
		box-shadow: 0 0 0 10px var(--error);
	}

	100% {
		box-shadow: 0 0 0 0 var(--error);
	}
}

.border_error {
	border: solid 4px var(--error);
	border-radius: var(--radius);
	animation: pulse 2s infinite;
}