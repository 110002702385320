.modal-add-folder {
  display: flex;
  flex-direction: column;
  width: 340px;

  h2 {
    font-weight: var(--medium-weight);
    font-size: 20px;
    line-height: 110%;
    text-align: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0 30px;
    padding-inline: var(--modal-indent);
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}

.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > label {
    margin-bottom: 4px;
    color: #4f575e;
    font-size: 14px;
    line-height: 21px;
  }

  > input {
    height: 40px;
    padding: 0 10px;
    border: var(--gray-border);
    border-radius: var(--radius);
    background: var(--white-100);
    outline: none;
  }
}
