.processing {
  position: absolute;
  top: 1.5px;
  left: 135px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 425px;
  height: calc(100% - 4px);
  border-radius: var(--modal-radius);
  overflow-y: hidden;
  background: var(--modal-bg);
  box-shadow: var(--modal-shadow);

  .strong {
    cursor: pointer;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 43px;
    padding: 0 15px;
    background-color: var(--modal-header-bg);

    &-title {
      font-size: 18px;
      line-height: 22px;

    }

    &-btn {
      margin-left: auto;
    }
  }

  &__container {
    flex-grow: 1;
    max-height: calc(100% - 55px);
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: content-box;
    max-height: calc(100% - 45px);
    padding-bottom: 5px;
    overflow-y: auto;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 20px 10px;
  }
}
