.replaceEdges{
	&__warning-container{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
	}

	&__btn-container{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		padding: 10px 0;
	}
}