.deleteProduct{
  min-width: 937px;
  height: calc(100% - 144px);
  padding: 13px 0 24px;

  &__header{
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
  }

  &__ahtungSection{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;

    &-container{
      max-width: 665px;
      text-align: center;
    }
  }

  &__table{
    height: calc(100% - 400px);
    overflow-y: auto;

    &-header{
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      height: 25px;
      padding-right: 33px;
      padding-left: 36px;
      background: var(--white-100);
    }

    &-number{
      width: 20px;
      padding-right: 30px;
      font-size: 14px;
      opacity: 0.7;
    }

    &-name{
      width: 100%;
      max-width: 450px;
      padding-right: 30px;
      padding-left: 8px;
      overflow: hidden;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      opacity: 0.7;
    }

    &-data{
      display: flex;
      align-items: center;
      width: 100%;

      &Item{
        flex: 1;
        font-size: 14px;
        text-align: center;
        opacity: 0.7;

      }

    }

    &-body{
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      padding-top: 4px;
    }

      &-item{
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        padding-right: 33px;
        padding-left: 36px;
        background: var(--white-100);
        box-shadow: -4.85px 6.48px 68.92px 0 #8484840A;

        &Style{
          color: var(--dark-900);
          opacity: 1;
        }
      }

  }

  &__productSection{
    padding-top: 20px;
    padding-bottom: 33px;

    &-title{
      margin-bottom: 20px;
      padding-left: 36.6px;
      font-size: 14px;
      opacity: 0.7;
    }

    &-container{
      display: flex;
      gap: 45px;
      align-items: center;
      width: 100%;
      height: 45px;
      padding-left: 36.6px;
      background: var(--white-100);
      box-shadow: -4.85px 6.48px 68.92px 0 #8484840A;
    }
  }

  &__footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 12px;

    &-container{
      display: flex;
      gap: 18px;
    }
  }
}