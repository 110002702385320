.toggle {
  display: flex;

  &__label {
    margin-right: 10px;
    background-color: var(--white-100);
  }
}

.selected {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: var(--border-width-medium) solid var(--dark-1000);
}
