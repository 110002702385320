.supportPage {
  width: 100%;
  min-width: 1024px;
  height: calc(100vh - 50px);
  overflow-y: auto;
  background-color: var(--gray-50);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 249px;
    background-color: #eff0f6;
  }

  &__header-title {
    color: var(--dark-1000);
    font-size: 28px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 115px);
    padding: 0 249px 34px;
  }

  &__body-form {
    margin: 86px 0;
  }

  &__body-title {
    display: flex;
    justify-content: center;
    margin-top: 34px;
    font-size: 25px;
  }

  &__questions {
    width: 100%;
    max-width: 1430px;
    margin: 0 249px;
    padding-bottom: 34px;
  }

  &__questions-toggle {
    display: flex;
    justify-content: center;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: #c1c1c1;
  }
}
