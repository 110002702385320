@import "./../../scss/variables";

.change-view {
	justify-content: space-between;

	&__control {
		border: 1px solid $color_main;
		display: flex;
		height: 30px;
		width: 30px;
		padding: 3px;
		cursor: pointer;
		background: $color_gray_t;
		opacity: 0.5;

		&.active {
			opacity: 1;
		}
	}
}