@import "./mixins.scss";
@import "./animtaions.scss";
@import "./variables.scss";
@import "./normalize.scss";
@import "./libs/index.scss";
@import "./helper.scss";

body {
  min-width: 1250px;
  min-height: 735px;
  overflow-x: auto;
  color: var(--gray-500);
  font-weight: var(--normal-weight);
  font-size: var(--font-size-base);
  background-color: #eff0f7;

  @media (width <= 480px) {
    min-width: 380px;
    max-width: 480px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.disable-scroll {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 11px; /* ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #d3d3d3; /* цвет фона трека */
}

::-webkit-scrollbar-thumb {
  background: var(--purple-400); /* цвет бегунка */
}

main {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  &.process-scene {
    z-index: 2;
    height: calc(100vh - 82px);
  }
}

.process-scene ~ .three-scene {
  display: block;
}

.projects {
  width: calc(100% - 144px);
  height: calc(100% - 60px);

  &__btn {
    display: inline-flex;
    padding-right: 8px;
    border: none;
    color: inherit;
    text-align: left;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    path {
      fill: var(--dark-1000);
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 24px;
  }

  .project-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    > h2 {
      color: var(--gray-700);
      font-size: 18px;
      line-height: 100%;
    }

    .project-actions {
      display: flex;
      align-items: center;

      &__search {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
      }

      &__input-title {
        margin-bottom: 10px;
        margin-left: 15px;
        font-size: 14px;
      }

      &__input {
        padding: 5px 10px;
        border: var(--border-width-thin) solid var(--dark-1000);
        border-radius: var(--form-field-radius);
        font-size: 20px;
        line-height: 24px;
      }

      > button {
        display: flex;
        width: auto;
        color: var(--purple-400);
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        background: none;

        > div {
          border-bottom: var(--purple-border);
        }
      }

      > button.import {
        margin-right: 20px;

        &::before {
          position: relative;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          background-image: url("./../media/file-o.svg");
          content: "";
        }
      }

      > button.save {
        &::before {
          position: relative;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          background-image: url("./../media/save.svg");
          content: "";
        }
      }
    }
  }
}

.title-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 30px;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;

  &_img {
    border-radius: var(--radius);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: var(--radius);
  background: var(--purple-400);
  cursor: pointer;

  &__icon-add {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../media/add.svg") no-repeat 50% 50%;
  }

  &__icon-map {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/scissors.svg") no-repeat center center;
  }

  &__icon-delete {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/trash-o.svg") no-repeat center center;
  }

  &__icon-cuttingParams {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/cuttingParams.png") no-repeat center center;
  }

  &__icon-angle-up {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 9px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../media/angle-down.svg") no-repeat 50% 50%;
      transform: rotate(180deg);
      content: "";
    }
  }

  &__icon-angle-down {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 9px;

    &::after {
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../media/angle-down.svg") no-repeat 50% 50%;
      content: "";
    }

  }

  &__icon-edit {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url("../media/edit.svg");
  }
}

.modal-state-enter {
  opacity: 0;
}

.modal-state-enter-active {
  opacity: 1;
  transition: opacity var(--transition);
}

.modal-state-exit {
  opacity: 1;
}

.modal-state-exit-active {
  opacity: 0;
  transition: opacity var(--transition);
}

button.delete {
  display: flex;
  width: auto;
  color: var(--purple-400);
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  background: none;
  opacity: 0.5;
  transition: var(--transition) all;
  pointer-events: none;

  &::before {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-image: url("./../media/trash-o.svg");
    content: "";
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  > div {
    border-bottom: var(--purple-border);
  }
}

.three-scene {
  align-items: center;
  justify-content: center;
  width: calc(100% - 198px);
  height: calc(100% - 195px);

  @media (height >= 800px) {
    height: calc(100% - 225px);
  }

  @media (width <= 480px) {
    max-width: 400px;
  }

  &.activated {
    display: flex;
  }

  &-sizes {
    width: 100%;
    height: 100%;

    @media (width <= 480px) {
      width: 80%;
      height: 80%;
    }
  }
}

.body::-webkit-scrollbar-track {
  background: transparent;
}

.body::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  background: #d0d0d0;
}

.body::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

.projects-list {
  display: flex;

  .projects-params {
    width: 275px;
    min-width: 275px;
    height: fit-content;
    margin-right: 10px;
    border-radius: var(--radius);
    background: var(--white-100);
    box-shadow: 0 1px 2px #00000019;

    .name {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 20px;
      color: var(--gray-700);
      line-height: 19px;
      user-select: none;
    }

    .items {
      padding: 0 10px;

      .projects__btn {
        path {
          fill: var(--purple-400);
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        margin: 4px 0;
        padding: 0 32px;
        border-radius: var(--radius);
        color: var(--purple-400);
        font-size: 14px;
        line-height: 100%;
        cursor: pointer;
        transition: var(--transition) all;

        &:hover {
          background: #fcf8fb;
        }

        &.active {
          background: #ffe7f7;
        }
      }
    }

    .add-project {
      margin: 20px;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        border: var(--purple-border);
        border-radius: var(--radius);
        color: var(--purple-400);
        font-weight: var(--medium-weight);
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        background: var(--white-100);
        transition: var(--transition) all;
        user-select: none;

        &:hover {
          border: var(--purple-border);
          color: var(--white-100);
          background: var(--purple-400);
        }
      }
    }
  }

  .projects-items {
    width: 100%;

    > table {
      width: 100%;
    }

    th {
      height: 50px;
      border: none;
      background: none;
    }

    td {
      height: 71px !important;
      border: none;
      border-bottom: var(--border-width-thin) solid #f5f5f5;
      user-select: none;
    }
  }
}

body.sidebar-open {
  overflow: hidden;

  &::before {
    position: fixed;
    top: 0;
    z-index: 98;
    width: 100%;
    height: 100%;
    background: #A0A0A03D;
    box-shadow: 0 1.736px 1.736px 0 #FFFFFF26 inset, 0 0 26.046px 0 #FFFFFF0C inset;
    content: "";
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}