.modal-save-project {
  width: 423px;


  &__title {
    font-weight: var(--medium-weight);
    font-size: 18px;
    line-height: 22px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding-inline: var(--modal-indent);
    margin-top: 20px;
    margin-bottom: 25px;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
  }

  &__form-control {
    display: flex;
    flex-direction: column;

    &-label {
      margin-bottom: 13px;
      font-size: 14px;
      line-height: 17px;

    }

    &-input {
      display: flex;
      align-items: center;
      height: 42px;
      padding: 0 10px;
      border:none;
      line-height: 25px;
      background-color: var(--form-field-bg);

    }

    &-input:focus {
      outline: none;
    }

    &-select {
      display: flex;
      align-items: center;
      height: 49px;
      border: var(--border-width-thin) solid var(--dark-1000);
      border-radius: var(--form-field-radius);
      line-height: 19px;
      cursor: pointer;
    }

    &-options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      margin-top: 22px;

      &_active {
        display: none;
      }

      &_item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 25px;
        border-radius: 9px;
        font-size: 22px;
        line-height: 26px;
        background: #f2eaf1;
      }
    }
  }
}
