.success-modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 486px;
    padding: calc(var(--modal-indent) * 2);
    font-size: 20px;

    &-img {
      width: 59px;
      height: 59px;
      margin-bottom: 20px;
      background: url("../../../../media/OK.svg");
    }

    &-text {
      margin-bottom: 39px;
      font-size: 20px;
    }

    &-btn {
      margin-top: auto;
    }
  }
}
