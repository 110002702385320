@import "../../../../../styles/mixins.scss";

.materialResiduesItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  margin-top: 9px;
  padding-right: 16px;
  background: var(--white-100);

  &__textContent {
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__itemContainerForSmallRow{
    @include for-fullHD-up {
      max-width: 150px
    }
  }

  &__material{
    width: 100%;
    max-width: 490px;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}



