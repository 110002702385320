.replaceEdgesProject{
	max-height: 552px;

	&__footer{
		display: flex;
		justify-content: center;
	}

	&__replaceContainer{
		height: 100%;
		padding-top: 20px;
        padding-inline: var(--modal-indent);
	}

	&__edges-none{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		min-height: 53px;
	}
}