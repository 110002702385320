@import "../scss/variables";

.constructor {
	width: 100%;
	height: calc(100vh - $headerHeight);
	overflow: hidden;

	.three-scene {
		width: 100%;
		height: 100%;
	}
}


.threeD{
	width: calc(100% - 198px);
	&_openChosProceses{
		width: calc(100% - 820px);
	}
}

.change-view-panel {
	position: absolute;
	left: 10px;
	bottom: 10px;
}