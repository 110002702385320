@import 'src/styles/mixins.scss';

.replaceMaterialProject{
	display: flex;
	flex-direction: column;
	width: calc(100% - 52px);
	max-width: 1816px;
	height: calc(100% - 85px);
	max-height: 860px;
	overflow: auto;

	&__title{
		font-size: 18px;
		line-height: 22px;
    }

	&__header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__table-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16.5px;
		padding: 10px 60px 9px 19px;
		background: var(--white-100);
		box-shadow: var(--box-shadow);

		&_invincible{
			min-width: 27px;
		}

		&-name{
			padding-left: 70px;
		}
	}

	&__chooseMaterialProject{
		height: calc(100% - 106px);
		max-height: 177px;
		padding-inline: var(--modal-indent);
	}

	&__chooseMaterialProject-list{
		display: flex;
		flex-direction: column;
		gap: 5px;
		min-height: 59px;
		max-height: 177px;
		overflow-y: auto;
	}

	&__material{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 53px;
		padding: 10px 60px 9px 19px;
		background: var(--white-100);
		box-shadow: var(--box-shadow);

		&_active{
			background: var(--purple-50);
			box-shadow: var(--box-shadow);
		}
	}

	&__material2{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1759px;
		min-height: 59px;
		padding: 12px 60px 12px 19px;
		background: var(--white-100);
		box-shadow: var(--box-shadow);

		&_active{
			background: var(--purple-50);
		}

		&:hover{
          background: var(--purple-50);
          box-shadow: var(--box-shadow);
		}
	}

	&__material3{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 42px;
		padding: 0 35px 0 19px;
		background: var(--white-100);
        box-shadow: var(--box-shadow);
		cursor: pointer;
	}

	&__content-container{
		display: flex;
		gap: 15px;
		align-items: center;
		width: 100%;
		max-width: 650px;
	}

	&__data-container{
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 812px;
	}

	&__data-item{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 145px;
		text-align: center;
	}

	&__data-header{
		font-size: 14px;
		line-height: 17px;
	}

	&__data{
		font-size: 14px;
		line-height: 17px;
	}

	&__replaceMaterial-container{
		margin-top: 20px;
		margin-bottom: 25px;
        padding-inline: var(--modal-indent);
	}

	&__replaceMaterial-header{
		padding-bottom: 20px;
	}

	&__kronasMaterials{
		padding-bottom: 10px;
	}

	&__kronasMaterials-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__kronasMaterials-materialsList{
		min-height: 53px;
		margin: 14px 0;
		padding: 10px 0;
		background: var(--white-100);
	}

	&__kronasMaterials-footer{
		display: flex;
		justify-content: flex-end;
	}

	&__kronasMaterials-noneMaterial{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 53px;
	}

	&__customerWarehouse{
		max-height: 177px;
	}

	&__customerWarehouse-header{
		padding-bottom: 10px;
	}

	&__customerWarehouse-materialsList{
		display: flex;
		flex-direction: column;
		gap: 5px;
		min-height: 59px;
		max-height: 177px;
		overflow-y: auto;
	}

	&__footer{
		display: flex;
		align-items: center;
		justify-content: flex-end;
        padding-inline: var(--modal-indent);

		&-btns{
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	&__footer-info{
		display: flex;
		gap: 23px;
		align-items: center;
	}

	&__img{
		width: 33px;
		height: 33px;
	}

	&__name{
		width: 100%;
		line-height: 19px;
	}
}

.small{
	max-height:600px;
}