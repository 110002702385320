.threeD{
	width: calc(100% - 198px);

	&_openChosProceses{
		width: calc(100% - 820px);
	}

	@media (width <= 480px) {
		max-width: 480px;
	}
}