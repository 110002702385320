.importBlockModel{
	width: 100%;
	padding-top: 16px;

	&__title{
		margin: 0 0 15px 34px;
		color: var(--purple-400);
		font-weight: var(--medium-weight);
		font-size: 18px;
	}

	&__container{
		display: flex;
		gap: 6px;
	}

	&__info-block{
		display: flex;
		gap: 70px;
		align-items: center;
		width: 100%;
		height: 80px;
		padding: 7px 20px 7px 50px;
		background: var(--white-100);
      box-shadow: var(--box-shadow);
	}
}