@import "../../../../styles/mixins.scss";

.products {
  width: calc(100% - 459px);
  min-width: 1024px;
  height: calc(100% - 510px);
  min-height: 510px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-container {
      display: flex;
      gap: 15px;
    }
  }

  &__main{
    height: calc(100% - 92px);
    padding-inline: var(--modal-indent);
  }

  &__addProduct{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &__table{
    height: 100%;

    &-header{
      display: flex;
      align-items: center;
      width: 100%;
      height: 34px;
      background: var(--white-100);

      &Style{
        font-size: 14px;
        opacity: 0.7;
      }
    }

    &-number{
      width: 10px;
      padding-right: 57px;
      padding-left: 15px;
      text-align: center;
    }

    &-name{
      width: calc(100% - 248px);
    }

    &-body{
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: 100%;
      height: calc(100% - 40px);
      padding-top: 7px;
      overflow-y: auto;
    }

    &-item{
      display: flex;
      align-items: center;
      width: 100%;
      height: 52px;
      padding-right: 16.5px;
      background: var(--white-100);
    }

    &-input{
      width: calc(100% - 248px);
      height: 38px;
      padding: 0 15px;
      border: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: var(--form-field-bg);
      outline: none;
    }
  }

  &__deleteBtn{
    margin-left: auto;
  }

  &__footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-container{
      display: flex;
      gap: 18px;
      align-items: center;
      justify-content: space-between;
    }
  }
}