.product-tooltip {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 350px;
  height: 76px;

  &.react-tooltip {
    padding: initial;
  }

  &__image {
    width: 48px;
    height: 48px;
  }

  &__title {
    color: var(--purple-400);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}