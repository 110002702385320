@import "/src/styles/mixins.scss";

.type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  background: var(--white-100);
  box-shadow: var(--container-shadow);
  cursor: pointer;
  transition: var(--transition) all;
  user-select: none;

  @include for-desktop-up {
    width: 364px;
    height: 249px;
  }

  &:hover {
    box-shadow: 0 5px 30px #00000033;
  }

  .process-img {
    width: 100%;
    max-width: 265px;
    height: 100%;
    max-height: 123px;
  }

  .title {
    max-width: 290px;
    font-size: 19px;
    line-height: 26px;
    text-align: center;

    @include for-desktop-up {
      font-size: 21px;
    }
  }
}
