.groupChangeDiamHoles {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 434px;
  height: calc(100% - 142px);



  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 41px;
    padding: 0 16.5px;
    background: #F0E4ED;
  }

  &__main {
    width: 100%;
    height: calc(100% - 41px - 78px);

    &-section {
      height: calc(50% - 20px);
    }

    &-title {
      margin: 20px 0 12px;
      padding: 0 0 0 20.5px;
      font-size: 14px;

    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      height: calc(100% - 22px);
      overflow-y: auto;
    }
  }


  &__footer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 25px 0 23px;
  }

  &__noneHoles {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 28px);
    background: var(--white-100);

    &-text{
      font-size: 14px;
      opacity: 0.6;
    }
  }
}

.groupChangeDiamHolesItem{
  display: flex;
  gap: 11px;
  align-items: center;
  width: 100%;
  min-height: 90px;
  padding: 0 15px;
  background: var(--white-100);

  &__section {
    display: flex;
    flex-direction: column;
    width: 187px;
    height: 65px;
  }

  &__label{
    font-size: 14px;
    opacity: 0.6;
  }

  &__value {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    margin-top: 10px;
    padding-left: 10.5px;
    border: var(--border-width-medium) solid transparent;
    border-radius: var(--radius);
    background: var(--gray-50);
  }

  &__select{
    width: 100%;
    height: 100%;
    margin-top: 10px;
    padding-left: 10.5px;
    border: var(--border-width-medium) solid transparent;
    border-radius: var(--radius);
    background-color: var(--form-field-bg);
    cursor: pointer;
  }
}
