.join-to-ss-order-modal {
  display: flex;
  flex-direction: column;
  width: 423px;
  min-height: 200px;
  padding: 20px 25px;

  .materialResidues__loader2 {
    margin: auto;
    padding-top: initial;
  }

  &__form-control + &__form-control {
    margin-top: 15px;
  }

  &__error {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin: auto;

    &-text {
      text-align: center;
    }
  }

  &__img {
    display: flex;
    margin-inline: auto;
  }

  &__title {
    font-weight: var(--medium-weight);
    font-size: 18px;
  }

  &__title, &__text, &__label {
    line-height: 22px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
}
