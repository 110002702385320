.detailInfo {
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 100;
  width: 521px;
  border-radius: var(--modal-radius);
  background-color: var(--modal-bg);
  box-shadow: var(--modal-shadow);

  @media (width <= 480px) {
    top: 20px;
    left: 10px;
    width: 100%;
    max-width: 350px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: move;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #FBFBFB;

    @media (width <= 480px) {
      max-width: 360px;
    }
  }

  &__param {
    margin-bottom: 4px;
  }

  &__actions {
    display: flex;
    gap: 19px;
    justify-content: flex-end;
  }

  &__texture{
    display: flex;
    gap: 25px;
    align-items: center;
    padding-left: 16px;

    &__center{
      display: flex;
      justify-content: space-around;
      padding-top: 20px;
      padding-left: 5px;
    }
  }

  &__texture-title{
    line-height: 19px;

  }

  &__texture-btn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 49px;
    height: 24px;
    padding: 3px;
    border-radius: 28px;
    background: #f0e4ed;
    cursor: pointer;

    &_active{
      justify-content: flex-end;
      background: #f3e0ee;
    }
  }

  &__texture-btn-item{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: var(--white-100);

    &_active{
      background: var(--purple-400);
    }
  }
}