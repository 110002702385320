@import "../../../../styles/mixins.scss";

.addMaterial {
  width: calc(100% - 30px);
  max-width: 1860px;
  height: calc(100% - 60px);

  @include for-modal-up {
    max-width: 906px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-searchField {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 526px;
      height: 30px;
      padding-right: 21px;
      padding-left: 21px;
      border-radius: 36px;
      background: #EFEFEF;
    }
  }

  &__input {
    width: 80%;
    border: none;
    line-height: 19px;
    background-color: transparent;
    outline: none;

    &-icon {
      display: inline-block;
      width: 13px;
      height: 15px;
      margin-right: 48px;
      background: url("../../../../media/loop.png");
    }
  }

  &__body {
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 110px);
    overflow: auto;
    background-color: var(--white-100);
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-right: 20px;
  }
}

.material {
  display: flex;
  align-items: center;
  padding: 20px 272px 20px 33px;

  @include for-modal-up {
    padding-right: 55px;
  }

  &__checkbox {
    margin-right: 30px;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 713px;

    @include for-modal-up {
      max-width: 283px;
    }
  }

  &__img {
    width: 47px;
    height: 27px;
    margin-right: 26px;
  }

  &__name {
    width: 100%;
    line-height: 19px;


    @include for-modal-up {
      max-width: 206px;
    }
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 729px;
    height: 37px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 145px;
    height: 40px;
    text-align: center;

    &-item {
      font-size: 14px;
      line-height: 17px;

    }

    &-item-second {
      line-height: 19px;

    }
  }
}

.deleteMaterial {
  &__text {
    padding: var(--modal-indent);
    font-size: 20px;
  }

  &__body {
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 178px);
    background: var(--white-100);
  }

  &__material-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  &__chosen-material {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 43px;
    border: none;
    border-radius: 50%;
    background-color: var(--purple-400);
    cursor: pointer;
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 37px;
  }

  &__icon {
    display: inline-block;
    width: 37px;
    height: 35px;
    background: url("../../../../media/texture.svg");
  }

  &__details-header {
    display: flex;
    width: 100%;
    padding: 10px 20px;
  }

  &__details-list {
    width: 100%;
    height: calc(100% - -37px - 71px - 109px);
    overflow: auto;
  }

  &__detail {
    display: flex;
    padding: 10px 20px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 51px;
    margin-right: 26px;
    text-align: center;
  }

  &__name {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 432px;

    @include for-modal-up {
      max-width: 206px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 37px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
}
