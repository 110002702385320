@use "sass:math";

@mixin for-desktop-up {
  @media (width >= 1375px) {
    @content;
  }
}

@mixin for-modal-up {
  @media (width <= 1885px) {
    @content;
  }
}

@mixin for-fullHD-up {
  @media (width <= 1920px) {
    @content;
  }
}

@mixin pseudo($pos: absolute, $content: "") {
  position: $pos;
  content: $content;
}


$defaultSize: 16;

@function em($breakpoint) {
  @return math.div($breakpoint, $defaultSize) + em;
}
