@import "icon-svg";

@mixin iconButton($icon, $type, $color) {
	$fillColor: 'none';
	$strokeColor: $color;

	display: inline-block;
	margin: 0;
	padding: 0 0 0 25px;
	position: relative;
	font-size: 0;
	line-height: 0;
	border: none;
	cursor: pointer;
	height: 25px;


	@if($type != 'none') {
		background-color: $type;
	} @else {
		background-color: transparent;
	}

	&:after {
		content: url(create-svg($icon, $color));
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin iconBefore($icon, $color) {
	position: relative;
	padding-left: 30px;

	&::before {
		content: url(create-svg($icon, $color));
		position: absolute;
		left: 0;
	}
}