.slider{
  display: flex;
  background: var(--gray-75);

  &__btn{
    margin-bottom: 140px;
    border: none;
    background: none;
    cursor: pointer;

    &-left{
      margin-right: 60px;
      margin-left: 60px;

    }

    &-right{
      margin-right: 60px;
      margin-left: 60px;

    }
  }

  &__btn:disabled {
    cursor: not-allowed;
  }

  &__cards{
    display: flex;

    @media (width <= 480px) {
      flex-direction: column;

    }

    &-card{
      max-width: 511px;
      margin-right: 10px;
      margin-left: 10px;

      @media (width <= 480px) {
        max-width: 450px;
        height: auto;
        margin-bottom: 25px;
      }

      &-link{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-img1{
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &-img2{
        position: absolute;
        cursor: pointer;

        @media (width <= 480px) {
          width: 70px;
          height: 70px;
        }
      }

      &-title{
        margin-bottom: 20px;
        color: #9FA7B3;
        font-size: 18px;

        @media (width <= 480px) {
          text-align: center;

        }
      }

      &-name{
        width: 100%;
        height: 60px;
        font-size: 20px;

        @media (width <= 480px) {
          height: 100%;
          text-align: center;

        }
      }

      &-date{
        display: flex;
        align-items: center;
        margin-top: auto;

        &-text{
          margin-left: 13px;
          color: #9FA7B3;
        }

      }
    }
  }
}