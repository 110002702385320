.tooltip-error {
  &__item {
    display: flex;
    gap: 10px;

    &-img {
      display: flex;
    }
    
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}