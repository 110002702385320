@import "../../scss/variables";

.panel-wrapper {
	position: fixed;
	z-index: 100;
	display: flex;
	flex-direction: column;
	width: $panelWidth;
	height: calc(100% - $headerHeight);
	padding: 5px;
	overflow-y: auto;
	background: $color_white;

	&-left {
		left: $panelHiddenWidth - $panelWidth ;
		transition: left .4s;

		&.opened {
			left: 0;

		}
	}

	&-header {
		display: flex;
		justify-content: flex-end;
		height: 32px;
		padding: 5px;
		background: $color_lite_gray;

		.icon-button-arrow::after {
			transform: scaleX(1) translate(-50%, -50%);
		}
	}

	&-body {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 5px;
		background: $color_lite_gray;
		opacity: 0;
		transition: opacity .4s;
	}

	&.opened &-body {
		opacity: 1;
	}

	&.opened &-header {
		.icon-button-arrow::after {
			transform: scaleX(-1) translate(50%, -50%);
		}
	}
}