.costPage {
  min-width: 1024px;
  margin: 2rem 4rem;

  &__title{
    margin: 1rem;
  }

  &__general{
    padding-bottom: .5rem;
  }


  &__title-main{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    font-size: 20px;

    &-item{
      margin-right: 15px;


    }

    &-one{
      margin-right: 100px;
      font-weight: var(--bold-weight);

    }
  }
}
