.deleteFurniture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  height: 100%;
  max-height: 215px;
  padding: 17px 26px;


  &__header {
    display: flex;
    justify-content: center;
    width: 100%;

    &-title {
      font-size: 18px;
      line-height: 22px;

    }
  }

  &__btn {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 6px 55px;
    border-color: transparent;
    border-radius: 30px;
    background: #f0e4ed;
    cursor: pointer;

  }
}
