@keyframes errorValidationOnInputAnimation {
  0% {
    border-color: var(--error);
    box-shadow: 0 0 5px var(--error);
  }

  50% {
    border-color: transparent;
    box-shadow: 0 0 0 var(--error);
  }

  51% {
    border-color: var(--error);
    box-shadow: 0 0 5px var(--error);
  }

  100% {
    border-color: transparent;
    box-shadow: 0 0 0 var(--error);
  }
}

@keyframes errorReplaceEdge {
  0% {
    color: var(--dark-1000);
    box-shadow: 0 0 5px var(--error);
  }

  50% {
    color: var(--error);
    box-shadow: 0 0 0 var(--error);
  }

  100% {
    color: var(--dark-1000);
    box-shadow: 0 0 0 var(--error);
  }
}

@keyframes errorValidationOnLabelAnimation {
  0% {
    color: var(--error);
  }

  50% {
    opacity: 0.6;
  }

  51% {
    color: var(--error);
  }

  100% {
    opacity: 0.6;
  }
}

.errorValidationOnInput {
  border: solid 2px transparent;
  animation: errorValidationOnInputAnimation 2s forwards;
}

.errorValidationOnLabel {
  animation: errorValidationOnLabelAnimation 2s forwards;
}

.errorOnSearch {
  animation: errorReplaceEdge 2s forwards infinite;
}
