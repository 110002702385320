.modalCoordinate{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 268px;
  background-color: var(--white-100);
  box-shadow: 0 6.68px 29px 0 #00000029;

  &-error{
    color: var(--error);
    font-size: 14px;
  }

  &-close{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 220px;
    border: none;
    background-color: var(--white-100);
    cursor: pointer;
  }

  &-blockMain{
    z-index: 1000;
    padding: 15px 15px 20px;

    &-block{
      width: 100%;

      &-label{
        color: #41414199;
        font-size: 14px;
        line-height: 16.8px;
        text-align: left;

      }

      &-input {
        top: 27px;
        display: flex;
        gap: 10px;
        align-items: center;
        width: 224px;
        height: 38px;
        margin-top: 9px;
        margin-bottom: 9px;
        border:none;
        border-radius: var(--radius);
        line-height: 25px;
        background: var(--form-field-bg);
      }
    }
  }

  &-button{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}