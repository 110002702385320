.inputModal{
	width: 80%;


    &__header{
      display: flex;
      text-align: center;

      &_input{
        width: 100%;
        padding-left: 5px;
        border: var(--border-width-thin) solid var(--purple-400);
        border-radius: var(--radius);
      }

      &_name{
        width: 60%;
        padding-left: 5px;
        border: var(--border-width-thin) solid var(--purple-400);
        border-radius: var(--radius);
      }
    }

	&__listProject{
        max-height: 600px;
		overflow: auto;
        text-align: center;

		&_item{
			display: flex;
			width: 100%;
			height: 41px;
			cursor: pointer;

			&-sub{
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: var(--border-width-thin) solid #A0A0A03D;
				border-bottom: var(--border-width-medium) solid #A0A0A03D;
			}
		}

		&_item:hover{
			background: #f0e4ed;
		}

		&_not-found{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			font-size: 24px;

		}
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&_id{
		width: 10%;
	}

	&_name{
		justify-content: start;
		width: 55%;
		padding-left: 20px;
	}
}