.flexible-modal-mask {
  display: none;
}

/* Animation */

.modal-state-enter {
  opacity: 0.01;
}

.modal-state-enter-active {
  opacity: 1;
  transition: var(--transition) all;
}

.modal-state-exit {
  opacity: 1;
}

.modal-state-exit-active {
  opacity: 0.01;
  transition: all var(--transition) ease-out;
}

.flexible-modal-drag-area {
  background: none !important;
}