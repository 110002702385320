.details-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: var(--white-100);

  &__wrapper {
    max-height: calc(100vh - 28rem);
    overflow: auto;
  }

  &-row-header {
    position: sticky;
    top: 0;
    z-index: 2;
    border: 0.05rem solid #CED4DAFF;
    background-color: #F3F3F3FF;

  }

  &-header {
    padding: 0.5rem;
    text-align: center;
  }

  &-item {
    border: 0.05rem solid #CED4DAFF;
    color: #1E152AFF;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;

    &-first {
      width: 5rem;
      overflow: hidden;
      background-color: #F3F3F3FF;
      padding-block: 0.5rem;
    }

    &__name {
      max-width: 50rem;

      p {
        display: -webkit-box;
        overflow-x: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
  }
}
