@import 'src/styles/mixins.scss';

.materials {
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  margin-bottom: 11px;
  padding: 10px 14px;
  background: var(--gray-50);
  box-shadow: var(--container-shadow);

  &__subconteiner{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 30%;
  }

  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
  }

  &__content-container{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__glue{
    position: relative;
    display: flex;
    place-content: center space-between;
    align-items: center;
    width: 241px;
    height: 45px;
    background: var(--gray-75);
    cursor: pointer;

    &_text{
      padding-left: 15px;
    }

    &_arrow-down{
      margin-right: 15px;
      transition: var(--transition) ease;
    }

    &_arrow-rotate{
      margin-right: 15px;
      transform: rotate(180deg);
      transition: var(--transition) ease;
    }
  }

  &__select{
    position: absolute;
    top: 40px;
    left: inherit;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 241px;
    height: auto;
    border-radius: var(--radius);
    background-color: var(--white-100);

    &_text{
      height: 25%;
      padding: 18px 22px;
      line-height: 30px;
      cursor: pointer;
    }

    &_text:hover{
      background: #F3E8F0;
    }
  }

  .options {
    display: flex;
    align-items: center;
    padding: 0 7px 0 10px;

    .name {
      line-height: 19px;
    }
  }

  .materials-class {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows var(--transition), padding-top var(--transition), height var(--transition);

    &__wrapper {
      overflow: hidden;
    }

    &--opened {
      grid-template-rows: 1fr;
      padding-top: 14px;
      transition: grid-template-rows var(--transition), padding-top var(--transition), height var(--transition);
    }
  }
}

.table {
  height: 100%;

  &__header {
    display: flex;
    padding: 7px 9px 7px 27px;
    background-color: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__data-container{
      display: flex;
      gap: 10px;
      width: 100%;
      max-width: 920px;
  }

  &__header-title{
      width: 100%;
      max-width: 155px;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
  }

&__number-and-name{
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding-right: 50px;

  &_edges{
    max-width: 630px;
  }
}

  &__number{
    box-sizing: content-box;
    width: 49px;
    text-align: center;
  }

  &__name{
    width: 100%;
    max-width: 526px;

    &_edges{
      max-width: 495px;
      padding-right: 25px;
    }
  }

  &__item{
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 8px 7px 8px 27px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__body-container{
    display: flex;
  }

  &__data{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 155px;
    font-size: var(--font-size-small);
    line-height: 19px;
    text-align: center;

    &-item{
      display: flex;
      width: 20px;
      height: 20px;
    }
  }
}

.btn_replace-material{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  background-color: var(--purple-400);
}

.edges {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1093px;

    &-add {
      display: flex;
      align-items: center;
      width: 140px;
    }

    &-name {
      margin-left: 10px;


    }
  }

  &__deleteBtnContainer{
    margin-left: auto;
  }
}


.materialItem{
  &__item-parts-container{
    padding: 9px 0;
  }

  &__item-parts-title{
    padding-left: 25px;
    font-size: 14px;
  }

  &__btn-container{
    display: flex;
    gap: 10.5px;
  }

  &__container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 600px;
  }

  &__number-and-name{
    max-width: 470px;
  }

  &__data-and-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
  }

  &__data-container{
    margin: 0;
  }
}

.errors{
  background: #F0E4ED;
}
