@import "mixin";
@import "variables";
@import "components/block";
@import "components/modal";

.remove-button {
	@include iconButton($cvg-cross, 'none', $color_icon)
}

.add-button {
	display: inline-block;
	margin-left: 5px;
	padding: 3px 5px 1px;
	border-radius: 2px;
	color: $color_icon;
	font-size: 17px;
	line-height: 17px;
	cursor: pointer;
}

.m {
	&b {
		&-2 {
			margin-bottom: 20px;
		}
	}
}

.d-flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.flex-justify-between, .justify-content-between {
	justify-content: space-between;
}

.align-items-center {
	align-items: center;
}

.flex-wrap {
	flex-wrap: wrap;
}

.hidden {
	display: none!important;
}

.c-pointer {
	cursor: pointer;
}

.icon-before {
	&-box {
		@include iconBefore($svg-box, $color_black);
	}

	&-connections {
		@include iconBefore($svg-connections, $color_black);

		padding-left: 20px;

		&::before {
			left: 4px
		}
	}

	&-cube {
		@include iconBefore($svg-cube, $color_black)
	}
}

.icon-button {
	&-arrow {
		@include iconButton($svg-arrow, 'none', $color_icon);

		&.hide {
			@include iconButton($svg-arrow-ver,'none', $color_icon);
		}
	}

	&-arrow-ver {
		@include iconButton($svg-arrow-ver,'none', $color_icon);
	}

	&-aye {
		@include iconButton($svg-aye,'none', $color_black);

		&.active {
			@include iconButton($svg-aye,'none', $color_icon);
		}
	}

	&-burger {
		@include iconButton($svg-burger,'none', $color_icon)
	}

	&-plus {
		@include iconButton($svg-plus,'none', $color_black);

		&.disabled {
			@include iconButton($svg-plus,'none', $color_icon);
		}
	}

	&-info {
		@include iconButton($svg-info,'none', $color_icon)
	}

	&-holes, &-showProcessing {
		@include iconButton($svg-dril, $color_white, $color_icon);

		&.active {
			@include iconButton($svg-dril, $color_white, $color_black);
		}
	}

	&-saw {
		@include iconButton($svg-saw, $color_white, $color_icon);

		&.active {
			@include iconButton($svg-saw, $color_white, $color_black);
		}
	}

	&-renderType_2 {
		@include iconButton($svg-view2, $color_white, $color_icon);

		&.active {
			@include iconButton($svg-view2, $color_white, $color_black);
		}
	}

	&-renderType_1 {
		@include iconButton($svg-view1, $color_white, $color_icon);

		&.active {
			@include iconButton($svg-view1, $color_white, $color_black);
		}
	}
}

.button-small {
	border: $color_icon;
	border-radius: 3px;
	color: $color_icon;
	font-size: 16px;
	cursor: pointer;

	&.active {
		color: $color_black;

	}
}

.constructor__input {
	width: 45%;
	padding: 5px 10px;
	border: 1px solid $color_icon;
	border-radius: 3px;
	font-size: 14px;


	&-group {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&-error {
		position: absolute;
		display: block;
		width: 100%;
		color: $color_red;
		font-size: 12px;
		transform: translate(43%, 21px);
	}

	&-label {
		width: 50%;
	}
}