@import "../../../../styles/mixins.scss";

.transportCut {
  width: calc(100% - 30px);
  height: calc(100% - 41px);

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 38px 0;

    &-title {
      font-size: 18px;
      line-height: 22px;

    }

    &-desc {
      width: 60%;
      margin-top: 10px;
      color: #1a1919;
      font-size: 17px;
      line-height: 22px;
    }
  }

  &__body{
    display: flex;
    width: 97%;
    height: 85%;
    margin: 10px 25px 25px;
    overflow-y: auto;
    background: var(--white-100);
    box-shadow: -4.2px 5.61px 59.6px 0 #8484840A;

    &-first{
      width: 75%;
      height: 100%;
      background-color: var(--white-100);

      &-title{
        margin: 25px 0 20px 20px;
        font-size: 20px;
        line-height: 21.6px;
        text-align: left;
      }

      &-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 620px;
        margin: 40px;

          > div:nth-last-child(-n+2) {
            margin-top: auto;
            margin-bottom: 40px;
          }

        @media (width <= 1700px) {
          > div {
            margin: 20px;
          }

          > div:nth-last-child(-n+2) {
            margin-top: auto;
            margin-bottom: 20px;
          }
        }
      }
    }

    &-second{
      position: relative;
      width: 35%;
      height: 100%;

      &-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 90%;
      }

      &-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 24px;
      }
    }

    &-greyLine{
      width: 100%;
      height: 1px;
      border: var(--border-width-thin) solid #0000001A;
    }

    &-line{
      width: 15px;
      height: 100%;
      background-color: #F9F9F9;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-right: 20px;
  }
}


.coordinate-system {
  position: relative;
   margin-left: 20px;
}

.y-axis {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 1px;
  height: 300px;
  background: var(--dark-1000);
}

.x-axis {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 300px;
  height: 1px;
  background: var(--dark-1000);
}

.x-axis::after {
  position: absolute;
  right: -6px;
  bottom: -4px;
  border-color: transparent transparent transparent #823535;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
}

.y-axis::after {
  position: absolute;
  top: -5px;
  left: -3px;
  border-color: transparent transparent #823535;
  border-style: solid;
  border-width: 0 5px 5px;
  content: "";
}

.x-label {
  position: absolute;
  right: -7px;
  bottom: -30px;
}

.y-label {
  position: absolute;
  top: -10px;
  left: -16px;
}

.highlighted {
   outline: 3px solid #ff0000; /* Substitute your preferred highlight color here */
 }