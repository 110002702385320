.modal {

	&__title {
		padding: 5px 10px;
		position: relative;
		width: 100%;

		.close-button {
			right: 10px;
			top: 5px;
			cursor: pointer;
		}
	}

	&__main {
		padding: 10px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 5px 10px;
	}
}