:root {
  --transition: 0.3s;
  --radius: 4px;
  --gray-50: #fcfcfc;
  --gray-75: #f6f6f6;
  --gray-100: #ced4da;
  --gray-200: #DCDCDC29;
  --gray-300: #717171;
  --gray-500: #414141;
  --gray-700: #272b30;
  --white-100: #ffffff;
  --purple-50: #A1438936;
  --purple-100: #ffedf9;
  --purple-400: #A14389;
  --dark-900: #1e152a;
  --dark-1000: #000000;
  --error: #f04444;
  --modal-bg: var(--gray-50);
  --modal-radius: 10px;
  --modal-header-bg: #F9F9F9;
  --modal-indent: 16px;
  --modal-shadow: 0px 9.79px 25.93px 0px #00000040;
  --backdrop: blur(17px);
  --form-field-bg: #F0F0F0;
  --form-field-radius: 11px;
  --border-width-thin: 1px;
  --border-width-medium: 2px;
  --font-family-primary: "Museo Sans Cyrl", sans-serif;
  --box-shadow: -5px 6.5px 69px #8484840A;
  --container-shadow: 0 6.679px 18.367px 0 #DCDCDC29;


  // Weights
  --light-weight: 300;
  --normal-weight: 400;
  --medium-weight: 500;
  --bold-weight: 700;
  --gray-border: var(--border-width-thin) solid var(--gray-100);
  --purple-border: var(--border-width-thin) solid var(--purple-400);
  --sale-accent-colour: #FFC800;
  --header-height: 50px;
  --font-size-base: 16px;
  --font-size-small: 14px;
  --font-size-large: 18px;
  --font-size-title: var(--font-size-large);
  --font-size-table-heading: var(--font-size-small);
}

$page-offset: 30px;