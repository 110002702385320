.imports-tooltip {
  &.react-tooltip {
    padding: 6px;
  }

  &__button {
    display: block;
    width: 100%;
    min-height: 38px;
    padding: 10px;
    font-size: var(--font-size-large);
    text-align: left;
    background-color: transparent;
    transition: background-color var(--transition);

    @media (hover) {
      &:hover {
        background-color: var(--purple-50);
        transition: background-color var(--transition);
      }
    }
  }
}