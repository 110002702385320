.customerWarehouse{
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1493px;
	padding: 23px 21px;



	&__header{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__table{
		padding-top: 14px;
	}

	&__table-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 35px;
		font-size: 14px;
		line-height: 17px;
		background: var(--white-100);
		box-shadow: var(--box-shadow);

	}

	&__data{
		display: flex;
		justify-content: center;
		width: 65px;
		font-size: 14px;
		text-align: center;
	}

	&__table-body{
		display: flex;
		flex-direction: column;
		gap: 10px;
		min-height: 100px;
		max-height: 336px;
		padding-top: 10px;
		overflow-y: auto;
	}

	&__table-item{
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__container{
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 1115px;
		height: 100%;
		max-height: 54px;
		background: var(--white-100);
	}

	&__data-content{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 100%;
		max-width: 1082px;
		padding: 11px 0;
	}

	&__footer{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 29.5px;
	}

	&__btns{
		display: flex;
		gap: 19px;
		align-items: center;
	}

	&__checkbox{
		width: 20px;
		height: 100%;
		margin-left: 12px;
	}

	&__sheetThickness{
		width: 105px;
	}

	&__warehouse{
		width: 170px;
	}

	&__name{
		width: 170px;
	}

	&__article{
    width: 150px;
  }

	&__name-data-container{
		display: flex;
		align-items: center;
		height: 54px;
	}

	&__name-data{
		display: -webkit-box;
		overflow: hidden;
		font-size: 14px;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	&__materialType{
		justify-content: flex-start;
		width: 100%;
		max-width: 280px;
		padding-left: 22px;
	}

	&__select-container{
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: flex-end;
		width: 340px;
		height: 54px;
		max-height: 102px;
	}

	&__select-container-background{
		display: flex;
		justify-content: flex-end;
		width: 320px;
		height: 54px;
		background: var(--white-100);
	}

	&__select{
		width: 100%;
		max-width: 327px;
		height: 54px;
		padding-left: 22px;
		border: none;
		line-height: 19px;
		cursor: pointer;
	}

	&__not-materials{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100px;

	}
}

