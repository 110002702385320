.kronasMaterials {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1002px;

  &__table {
    max-height: 165px;
    overflow-y: auto;
  }

  &__main {
    padding: var(--modal-indent);
  }

  &__table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 0 75px 0 29px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);

    &-item {
      font-size: 14px;
      line-height: 17px;

    }
  }

  &__table-body {
    width: 100%;
    padding-top: 9px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      margin-bottom: 9px;
      padding-left: 29px;
      background: var(--white-100);
    }
  }

  &__data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__actions {
    display: flex;
    gap: 19px;
    justify-content: flex-end;
  }

  &__select {
    width: 324px;
    height: 42px;
    padding-right: 12px;
    padding-left: 22px;
    border: none;
    line-height: 19px;
    background-color: var(--form-field-bg);
  }

  &__btn{
    margin-right: 7px;
  }

  &__no-items{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input{
    width: 100%;
    height: calc(100% - 15px);
    padding-left: 10px;
    border: none;
    border-radius: var(--radius);
    text-align: center;
    background-color: var(--form-field-bg);
    outline: none;

    &-name{
      text-align: left;
    }
  }

  &__default-input{
    width: 65px;
    height: calc(100% - 15px);
    border: none;
    border-radius: var(--radius);
    text-align: center;
    background-color: var(--form-field-bg);
    outline: none;
  }

  &__error{
    border: var(--border-width-medium) solid #df3b3b;
    background: #e6e1e1;
  }
}

.materialsKronas {
  width: 100%;
  padding-bottom: 39px;

  &__create {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }

  &__title {
    margin-right: 27px;
    font-size: 18px;
    line-height: 22px;

  }

  &__height-width{
    width: 65px;
    text-align: center;
  }

   &__sheet-thickness{
    width: 105px;
     text-align: center;
  }

  &__name{
    justify-content: flex-start;
    width: 199px;
  }

  &__materialType{
    width: 248px;
    padding-left: 22px;
    background: var(--gray-50);
  }
}

.edgesKronas {
  width: 100%;
  padding-bottom: 39px;

  &__create {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }

  &__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 858px;
    height: 100%;
  }

  &__height-width{
    width: 65px;
    text-align: center;
  }

  &__name{
    display: flex;
    justify-content: flex-start;
    width: 175px;
  }

  &__title {
    margin-right: 54px;
    font-size: 18px;
    line-height: 22px;

  }

  &__header{
    height: 42px;
    padding: 0 82px 0 29px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__header-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 858px;
    height: 100%;
  }

  &__type{
    width: 248px;
    padding-left: 22px;
    background: var(--gray-50);
  }
}
