.templateType{
  &-multiplicity{
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    margin-top: 10px;
    transform: rotate(90deg);
  }

  &-arrowVertical{
    position: absolute;
    bottom: 40px;
    left: 5px;
    display: flex;
  }

  &-arrowGorizontal{
    position: absolute;
    bottom: 1px;
    left: 30px;
  }

  &-title{
    padding-left: 3px;
    font-size: 14px;
  }

  &-titleVer{
    padding-top: 10px;
    font-size: 14px;
  }

  &-line{
    width: 4px;
    height: 40px;
    margin-right: 10px;
    background: #3f3d3d;
  }
}