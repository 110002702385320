@import '../../../../styles/mixins.scss';

.replaceMaterial{
  width: calc(100% - 30px);
  max-width: 1860px;
  height: calc(100% - 60px);
  max-height: 837px;
  padding: 0 30px 18px;



  @include for-modal-up {
    max-width: 906px;

  }

  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
  }

  &__select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 33px;
    padding-bottom: 20px;
  }

  &__select-item{
    width: 248px;
    height: 42px;
    border: var(--border-width-thin) solid var(--purple-400);
    background-color: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__select-container{
    display: flex;
    gap: 22px;
    align-items: center;
  }

  &__body{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 147px);

  }

  &__materials-list{
    width: 100%;
    max-height: calc(100% + 37px - 71px - 300px);
    overflow: auto;
    background: var(--white-100);
  }

  &__details-header{
    display: flex;
    width: 100%;
    padding: 10px 20px;
    background: var(--white-100);
  }

  &__details-list{
    width: 100%;
    height: calc(100% + 37px - 71px - 150px);
    overflow: auto;
    background: var(--white-100);
  }

  &__btns{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: auto;
  }

  &__btn{
    display: flex;
    align-items: center;
    width: 250px;
    height: 80px;
    margin-bottom: 20px;
    padding: 10px;
    border-color: transparent;
    border-radius: 20px;
    color: var(--white-100);
    background: var(--purple-400);
    cursor: pointer;
  }
}

.list-without-materials{
  width: 100%;
  height: calc(100% + 37px - 71px - 75px);
  overflow: auto;
  background: var(--white-100);
}