.video-modal-content {
  width: 80%;
  height: 80%;
  border-radius: var(--modal-radius);
  overflow: hidden;
  background: var(--modal-bg);
}

.close-button {
  position: absolute;
  top: 30px;
  right: 80px;
}