.edgesHelper {
	width: 580px;
	max-height: 300px;

	&__material-container{
		padding-top: 9px;
		padding-bottom: 8px;
		padding-left: 21px;
		background: var(--white-100);
	}

	&__material-header{
		display: flex;
		gap: 5px;
		align-items: center;
	}

	&__material-name{
		display: flex;
		gap: 19px;
		align-items: center;
	}

	&__material-title{
		margin-bottom: 12px;
	}

	&__edges{
		display: flex;
		flex-direction: column;
		gap: 5px;
		background: var(--gray-50);
	}

	&__edge{
		display: flex;
		align-items: center;
		padding: 4px 0 4px 21px;
		background: var(--white-100);
		box-shadow: var(--container-shadow);
	}

	&__edge-container{
		display: flex;
		gap: 20px;
		align-items: center;
	}

	&__edge-container-img{
		width: 21px;
	}

	&__text{
		color: var(--purple-400);
		font-size: 14px;
	}

	&__text2{
		font-size: 14px;
	}
}
