.radio-custom {
  position: absolute;
  border: none;
  outline: none;
  opacity: 0;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
}

.radio-custom, .radio-custom-label {
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

.radio-custom-label {
  position: relative;

  &:focus {
    border: none;
  }
}

.radio-custom + .radio-custom-label::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding: 2px;
  border: 3px solid var(--purple-400);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background: var(--white-100);
  content: '';
}

.radio-custom:checked + .radio-custom-label::before {
  background: var(--purple-400);
  box-shadow: inset 0 0 0 3px var(--white-100);
}

.radio-custom:focus + .radio-custom-label {
  outline: var(--border-width-thin) solid #dddddd; /* focus style */
}
