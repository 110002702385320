:root {
  --rt-color-white: var(--white-100);
  --rt-color-dark: #222;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

.core-styles-module_tooltip__3vRRp {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
}

.core-styles-module_fixed__pcSol {
  position: fixed;
}

.core-styles-module_arrow__cvMwQ {
  position: absolute;
  background: inherit;
}

.core-styles-module_noArrow__xock6 {
  display: none;
}

.core-styles-module_clickable__ZuTTB {
  pointer-events: auto;
}

.core-styles-module_show__Nt9eE {
  opacity: var(--rt-opacity);
  transition: opacity var(--rt-transition-show-delay) ease-out;
}

.core-styles-module_closing__sGnxF {
  opacity: 0;
  transition: opacity var(--rt-transition-closing-delay) ease-in;
}

/** end - core styles **/

.styles-module_tooltip__mnnfp {
  padding: 8px 16px;
  width: max-content;
}

.styles-module_arrow__K0L3T {
  width: 8px;
  height: 8px;
}

[class*='react-tooltip__place-top'] > .styles-module_arrow__K0L3T {
  transform: rotate(45deg);
}

[class*='react-tooltip__place-right'] > .styles-module_arrow__K0L3T {
  transform: rotate(135deg);
}

[class*='react-tooltip__place-bottom'] > .styles-module_arrow__K0L3T {
  transform: rotate(225deg);
}

[class*='react-tooltip__place-left'] > .styles-module_arrow__K0L3T {
  transform: rotate(315deg);
}

.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.tooltip {
  z-index: 9998;
  border-radius: var(--radius);
  filter: drop-shadow(0px 7.677px 120px rgba(0, 0, 0, 0.5));
}