.increase-detail {
  display: flex;
  flex-direction: column;
  width: 377px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    padding-block: 20px;
  }

  &__btns {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-inline: var(--modal-indent);
    background-color: var(--white-100);

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-label {
      margin-bottom: 13px;
      color: #41414199;
      font-size: 14px;
      line-height: 17px;
    }

    &-input {
      display: flex;
      align-items: center;
      height: 42px;
      padding: 0 10px;
      border:none;
      border-radius:4px ;
      line-height: 25px;
      background:var(--form-field-bg);
    }

    &-error{
       margin-top: 10px;
       color: #b82e2e;
    }

    &-input:focus {
      outline: none;
    }

    &-options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      margin-top: 22px;

      &_active {
        display: none;
      }

      &_item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 25px;
        border-radius: 9px;
        font-size: 22px;
        line-height: 26px;
        background: #f2eaf1;
      }
    }
  }
}
