.modal-materials-add {
  width: 90%;
  max-width: 1222px;
  height: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding-top: 30px;

    &-title {
      width: 100%;
      padding-left: 65px;
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    padding-right: 20px;
  }

  &__search-field {
    display: flex;
    align-items: center;
    width: 381px;
    height: 65px;
    border-radius: 36px;
    background: #F0E4ED7C;

    &-loop {
      display: inline-block;
      min-width: 24px;
      height: 22px;
      margin-right: 26px;
      margin-left: 26px;
      background: url(../../../../media/loop.svg) no-repeat;
    }

    &-input {
      width: 100%;
      height: 28px;
      padding-right: 26px;
      border: none;
      font-size: 21px;
      line-height: 26px;
      background: transparent;
      outline: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 30px 36px 0;
    font-size: 25px;
    line-height: 30px;

    @media screen and (width <= 1378px) {
      font-size: 18px;
    }
  }

  &__list {
    width: 100%;
    padding: 16.5px 0;
    border-radius: 29px;
    background-color: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__material {
    display: flex;
    align-items: center;
    padding: 0 33px 0 30px;

    &-container {
      display: flex;
      align-items: center;
      margin-right: auto;

      .modal-materials-add__material-img {
        width: 97px;
        height: 107px;
        border-radius: 22px;
      }

      .modal-materials-add__material-title {
        width: 100%;
        max-width: 424px;
        height: 100%;
        max-height: 60px;
        margin-left: 28.4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 29px;
    width: 100%;
    max-width: 776px;
    height: 85px;

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 776px;

      &-item {
        width: 100%;
        max-width: 113px;
        text-align: center;
      }
    }

    &-body {
      display: flex;
      justify-content: space-between;

      &-item {
        width: 113px;
        text-align: center;
      }
    }
  }

  &__edges-list {
    margin-top: 20px;
    padding: 0 30px 11px;
  }

  &__edge-main-title {
    min-width: 160px;
    margin-right: 50px;
    margin-left: 42px;
    font-size: 21px;
    line-height: 26px;
  }

  &__edges {
    display: flex;
    flex-wrap: wrap;

    &-header {
      display: flex;
      gap: 22px;
      align-items: center;
    }

    &-container {
      display: flex;
      gap: 25px;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        border: var(--border-width-thin) solid var(--purple-50);
        border-right: none;
        border-left: none;
      }

      &-line {
        height: 135px;
        border: var(--border-width-thin) solid var(--purple-50);
      }
    }
  }

  &__edge {
    display: flex;
    align-items: center;
    width: 50%;
    height: 107px;

    &-line {
      width: 1390px;
      height: 1px;
      border: var(--border-width-thin) solid var(--purple-50);
    }

    &-container {
      display: flex;
      gap: 28px;
      align-items: center;
      height: 100%;
    }

    &-img {
      width: 95px;
      height: 104px;
    }

    &-title {
      width: 234px;
    }

    &-table {
      display: flex;
      flex-direction: column;
      gap: 19px;
      width: 327px;
      margin-left: 29px;

      &-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;

      }

      &-body {
        display: flex;
        gap: 40px;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;

        &-item {
          width: 91px;
          font-size: 21px;
          line-height: 27px;
          text-align: center;
        }
      }
    }
  }

  &__btns {
    display: flex;
    gap: 16.5px;
    justify-content: center;
    max-width: 100%;
    padding: 32px 0 27px;
  }

  &__btn {
    width: 100%;
    max-width: 381px;
    height: 59px;
    border: none;
    border-radius: 36px;
    font-size: 21px;
    line-height: 26px;
    background-color: #f0e4ed;
    box-shadow: var(--container-shadow);
    cursor: pointer;
    transition: color var(--transition) background-color var(--transition);

    &:hover {
      color: var(--white-100);
      background-color: var(--purple-400);
      transition: color var(--transition) background-color var(--transition)
    }

    &:disabled {
      color: var(--white-100);
      background: linear-gradient(114.68deg, #a14389 15.84%, #501c54 172.37%);
      cursor: auto;
    }
  }

  &__checkbox {
    margin-left: 45px;
  }
}

.btn-deleteAll {
  transition: all var(--transition);

  &:hover {
    color: var(--white-100);
    background: #ff5e5e;
  }
}

.icon {
  &__long {
    width: 17px;
    height: 16px;
    background: url(../../../../media/long.svg);
  }

  &__long-x {
    width: 21px;
    height: 15px;
    background: url(../../../../media/long-x.svg);
  }

  &__long-y {
    width: 15px;
    height: 23px;
    background: url(../../../../media/long-y.svg);
  }
}

.number {
  display: flex;
  flex-direction: column;
  gap: 34px;
  align-items: center;
  margin-right: 19px;

  &__content {
    font-size: 21px;
    line-height: 25px;

  }
}
