.importErrorModal {
	width: calc(100% - 30px);
	max-width: 832px;
	min-height: 300px;
	max-height: 502px;



	&__header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 14px 14px 0 0;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(100% - 30px);

	}

	&__body-text-error {
		padding-bottom: 21px;
		font-size: 25px;
	}

	&__required-list {
		display: flex;
		flex-direction: column;
		gap: 9px;
		align-items: center;
		width: 100%;
	}

	&__item {
		display: flex;
		gap: 32px;
		align-items: center;
		width: 100%;
		padding: 13.5px 0;
		background: var(--purple-50);
	}

	&__item-text {
		display: flex;
		flex: 3;
		gap: 5px;
		justify-content: flex-end;

		&_firstWord {
			color: var(--purple-400);
		}
	}

	&__item-text2 {
		flex: 2;
		color: var(--purple-400);
	}
}