@import "../../scss/variables";

.detail-connections {
	margin-left: 10px;
	width: calc(100% - 10px);
	background: $color_gray_t;
	padding: 5px;

	&__item{

		&-text {
			font-size: 12px;
		}
	}
}