.authorize {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.plug {
      width: 40%;
    }

    &.form-plug {
      width: 60%;
    }

    >img {
      width: 100%;
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      object-fit: cover;
    }
  }

  h2 {
    margin-bottom: 32px;
    color: var(--dark-900);
    font-weight: var(--medium-weight);
    font-size: 20px;
    line-height: 21px;
    text-align: center;
  }

  .form {
    width: 240px;
    min-width: 240px;

    .action {
      margin-top: 40px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        border: var(--purple-border);
        border-radius: var(--radius);
        color: var(--white-100);
        font-weight: var(--medium-weight);
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        background: var(--purple-400);
        opacity: .5;
        transition: var(--transition) all;
        pointer-events: none;

        &:hover {
          border: var(--purple-border);
          color: var(--purple-400);
          background: var(--white-100);
        }

        &.active {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    .reg-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      a {
        display: flex;
        align-items: center;
        color: var(--purple-400);
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }

      .register {
        display: flex;
        margin-top: 20px;

        >div {
          display: flex;
          align-items: center;
          margin-right: 15px;
          color: var(--dark-900);
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}