.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 1024px;
  background: #EFF0F7;
  box-shadow: var(--container-shadow);

  &_edit_save{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__options {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 17px 0 0;
    user-select: none;

    &-add{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 235px;
      height: 45px;
      background: var(--white-100);
      cursor: pointer;
      transition: width var(--transition);

      &_sub{
        display: flex;
        align-items: center;
      }

      &_edit{
        display: flex;
        margin-right: 12px;
        border: 0;
        border-radius: var(--radius);
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
          opacity: .6;
        }
      }
    }

    &-add_editMode{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 478px;
      height: 45px;
      background: var(--white-100);
      transition: width var(--transition);

      &_wraper{
        display: flex;
        gap: 20px;
      }

      &_block{
        display: flex;
        gap: 10px;

        &_title{
          line-height: 22px;

        }
      }

      &_sub{
        display: flex;
        align-items: center;
      }

      &_edit{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 12px;
        border-radius: var(--radius);
        cursor: pointer;
      }

      &__edges-mode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 49px;
        height: 24px;
        padding: 3px;
        border-radius: 28px;
        background: #f0e4ed;
        cursor: pointer;

        &_active {
          justify-content: flex-end;
          background: #f3e0ee;
        }

        &-item {
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: var(--white-100);

          &_active {
            background: var(--purple-400);
          }
        }

        &-text {
          margin-left: 50px;
          font-size: 20px;
          line-height: 24px;

        }
      }
    }

    &-title {
      margin: 0 24px 0 10px;
      line-height: 22px;
      cursor: pointer;
    }

  }

  &__select {
    width: 100%;
    max-width: 345px;
    height: 37px;
    margin-left: auto;
    font-size: 14px;
  }

  &__filter{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 37px;
    border: none;
    background: var(--white-100);
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: .6;
    }

    &-text{
      font-size: 14px;
    }
  }

  &__icon{
    width: 20px;
    height: 20px;
    margin-left: 23px;
    background: url("../../media/filter.png") no-repeat center center;
  }

  &__quick-input-table {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 38px;
    border: none;
    border-radius: var(--radius);
    color: var(--white-100);
    font-size: 14px;
    line-height: 17px;
    background: var(--purple-400);
    box-shadow: 0 6.803px 18.708px var(--container-shadow);
    cursor: pointer;
  }

  &__products {
    display: flex;
    gap: 2.85px;
    align-items: center;
    justify-content: center;
    width: 97px;

    &-icon{
      display: block;
      width: 13.3px;
      height:13.3px;
      background: url("../../media/plus.png");
    }
  }

  &__upload-detail{
    position: relative;
    height: auto;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;

    &-img{
      height: 40px;
      border-radius: var(--radius);
    }
  }

  &__empty-project {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
}

.import-project{
  position: absolute;
  z-index: -1;
  width: 220px;
  opacity: 0;
}

.bgSelect{
  background: var(--white-100);
}

.bgNoSelect{
  background: #FCFCFC80;
}