@import "../../../../styles/mixins.scss";

.quickInputTable {
  width: calc(100% - 130px);
  min-width: 1024px;
  height: calc(100% - 100px);
  overflow: hidden;


  &__sticky-container {
    width: 100%;
    background: var(--gray-50);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    min-height: 330px;
  }

  &__materials-edges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1659px;
    padding: 0 27px;
    background: var(--gray-50);
  }

  &__info {
    width: 100%;
    max-width: 798px;
    overflow: hidden;

    &-header {
      display: flex;
      margin-left: 25px;
      padding-top: 18px;
      padding-bottom: 18px;

      &-title {
        margin-right: 33px;
        font-size: 19px;
        line-height: 28px;

      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        border: none;
        border-radius: 6px;
        background: var(--purple-400);
        cursor: pointer;
      }
    }

    &-body {
      width: 100%;
      max-width: 798px;
      height: 91px;
      overflow: hidden auto;

      &_active {
        display: none;
        overflow: hidden;
      }

      &-item {
        display: flex;
        gap: 27px;
        align-items: center;
        width: 100%;
        max-width: 798px;
        height: 42px;
        margin-top: 12px;
        padding-left: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: var(--white-100);
        box-shadow: var(--box-shadow);

        &:first-child {
          margin-top: 0;
        }

        &-number {
          font-size: 18px;

        }

        &-img {
          width: 44px;
          height: 27px;
          border-radius: 8px;
        }

        &-name {
          max-width: 100%;
          overflow: hidden;
          font-size: 18px;
          line-height: 22px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__addDetail {
    display: flex;
    gap: 33px;
    align-items: center;
    height: 71px;
    padding-left: 51px;
    background: var(--gray-50);

    &-title {
      font-size: 19px;
      line-height: 28px;

    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      border: none;
      border-radius: 6px;
      background: var(--purple-400);
      cursor: pointer;
    }
  }

  &__edges-mode {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 49px;
    height: 24px;
    padding: 3px;
    border-radius: 28px;
    background: #f0e4ed;
    cursor: pointer;

    &_active {
      justify-content: flex-end;
      background: #f3e0ee;
    }

    &-item {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: var(--white-100);

      &_active {
        background: var(--purple-400);
      }
    }

    &-text {
      margin-left: 50px;
      font-size: 20px;
      line-height: 24px;

    }
  }

  &__table {
    height: 100%;
    padding-right: 29px;
    padding-bottom: 25px;
    padding-left: 30px;
    font-size: 20px;
    line-height: 24px;

  }

  &__table-header {
    display: flex;
    align-items: center;
    height: 37px;
    margin: 0 28px;
    padding-left: 26px;
    background-color: var(--white-100);

    &-main-container {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 775px;
      margin-right: 2px;
      padding-left: 23px;

      @include for-desktop-up {
        gap: 0;
        font-size: 18px;
      }
    }

    &-item {
      min-width: 61px;
      max-width: 99px;
      font-size: 14px;
      text-align: center;

      @include for-desktop-up {
        width: 100%;
        min-width: 0;
        font-size: 18px;
      }
    }

    &-number {
      width: 15px;
    }

    &-multiplicity {
      width: 100%;
      max-width: 99px;
      font-size: 14px;
      text-align: center;

      @include for-desktop-up {
        font-size: 18px;
      }
    }

    &-texture {
      width: 100%;
      max-width: 152px;
      font-size: 14px;
      text-align: center;

      @include for-desktop-up {
        font-size: 18px;
      }
    }

    &-name {
      width: 100%;
      max-width: 171px;
      font-size: 14px;
      text-align: center;

      @include for-desktop-up {
        font-size: 18px;
      }
    }
  }

  &__table-body {
    width: 100%;
    height: calc(100% - 288px);
    padding: 0 26px 15px;
    overflow-y: auto;

    &_active {
      height: calc(100% - 360px);
      padding: 0 26px 15px;
      overflow-y: auto;
    }
  }

  &__content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;

    @include for-desktop-up {
      padding-left: 64px;
    }
  }

  &__edges-helper{
    position: absolute;
    top: 215px;
    left: 0;
    z-index: 101;
    display: none;
    flex-direction: column;
    width: 568px;
    max-height: 569px;
    padding: 8.5px 0;
    overflow-y: auto;
    background: var(--white-100);
    box-shadow: 0 7.677px 120px 0 #00000033;

    &_active{
      display: flex;
    }
  }

  &__edges-helper-item{
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 8px 22px 7px 20px;
  }

  &__addDetails{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 20px;

  }

  &__btn-save{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.edgesHelper{
  &__img{
    width: 28px;
    height: 27px;
  }
}

.polygon-down {
  display: inline-block;
  width: 10px;
  height: 9px;
  background: url("../../../../media/polygon-down.svg");

  &_active {
    transform: rotate(180deg);
  }
}

.label-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 69px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background: var(--purple-400);

  @include for-desktop-up {
    width: 35px;
    height: 35px;
  }

  &__triangle-down {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../../../../media/triangle-down.png");
  }

  &__triangle-up {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../../../../media/triangle-top.png");
  }

  &__triangle-right {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../../../../media/triangle-right.png");
  }

  &__triangle-left {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("../../../../media/triangle-left.png");
  }

  &__texture {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url("../../../../media/texture.svg");
  }
}
