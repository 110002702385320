@import "../../scss/variables";
.used-details {

	&__group {
		padding: 5px 5px 5px 10px;
		background: #ccc;
		margin: 3px 0;

		&-title {
			margin: 2px 0;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 600;
		}
	}

	&__item {
		align-items: center;

		&-title {
			align-items: center;
			padding-left: 10px;

			.icon-button-arrow {
				margin-left: -10px;
			}

			&-content {
				width: 100%;
				position: relative;
				font-weight: 400;

				span {
					font-size: 14px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
				}
			}

			&-content:hover span {
				position: absolute;
				width: auto;
				top: -8px;
			}
		}

		&-control {
			width: 25px;
		}

		&-checkbox {
			width: 15px;
		}

		&-body {
			border-left: 1px solid $color_icon;
			margin-left: 11px;
			padding-left: 13px;
		}
	}
	.processing{
		&-item {
			align-items: center;
			padding: 2px 0;

			.button-small {
				margin-left: 2px;
			}
		}
		&-title {
			font-weight: 400;
		}

	}
}