@import "./../../../scss/variables";

.c_tooltip {
	position: fixed;
	z-index: 99;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 5px;
	background: $color_icon;
	transform: translate(58%, 24px);

	&::after {
		position: absolute;
		right: 29px;
		bottom: -5px;
		width: 10px;
		height: 10px;
		background: $color_icon;
		transform: rotateZ(45deg);
		content: ' ';
	}

	&.bottom {
		&::after {
			top: -5px
		}
	}

	&.left {
		&::after {
			left: 29px
		}
	}


	* {
		margin: 2px;
		font-weight: 400;
		font-size: 14px;
	}
}