@import "../../../../styles/mixins.scss";

.addHardware {
  width: 70%;
  max-width: 1860px;
  height: 75%;

  &__loader{
      position: absolute; /* Абсолютное позиционирование относительно ближайшего родителя с position: relative */
      bottom: 50%;         /* Привязка к нижней части контейнера */
      left: 50%;         /* Центрирование по горизонтали */
      z-index: 10; /* Уровень повыше, чтобы перекрывал контент */
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px; /* Высота `Loader` */
      transform: translateX(-50%); /* Смещение для точного центрирования */
      pointer-events: none; /* Делаем слой кликабельным сквозь */
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0 25px;

    &-title {
      font-size: 18px;
      line-height: 22px;
    }

    &-searchField {
      display: flex;
      align-items: center;
      width: 440px;
      max-width: 526px;
      height: 35px;
      padding-right: 21px;
      padding-left: 21px;
      border-radius: 36px;
      background: #F0E4ED7C;
    }
  }

  &__input {
    width: 370px;
    border: none;
    line-height: 19px;
    background-color: transparent;
    outline: none;

    &-icon {
      display: inline-block;
      width: 13px;
      height: 15px;
      margin-right: 15px;
      background: url("../../../../media/loop.png");
    }
  }

  &__colmHeader{
    display: flex;
    justify-content: space-between;
    padding: 10px 24px 10px 142px;
    background-color: var(--modal-header-bg);

    &-item{
      width: 10%;
      color: var(--gray-300);
      font-size: 14px;
    }

    &-item:nth-child(1) {
      width: 50%;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 144px);
    overflow: auto;
    background: var(--white-100);

    &_not_find{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 18px;
      line-height: 22px;
    }

    &-block{
      display: flex;
      justify-content: center;
    }

    &-list{
      display: flex;
      flex-direction: column;
      width: 100%;
      background: var(--gray-50);

      &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 12px;
        background: var(--white-100);
        box-shadow: 0 6.483px 115.520px 0 #9595953D;

        &__default-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 7%;
          height: 41px;
          padding-right: 15px;
          padding-left: 15px;
          border-radius: var(--radius);
          background-color: var(--form-field-bg);
        }

        &__default-input-small {
          display: flex;
          align-items: center;
          width: 60px;
          height: 35px;
          border: none;
          border-radius: var(--radius);
          color: var(--gray-300);
          font-size: 14px;
          text-align: center;
          background-color: var(--form-field-bg);
          outline: none;
        }

        &__input{
          width: 100%;
          border: none;
          line-height: 24px;
          background: var(--form-field-bg);
          outline: none;
        }

        &-img{
          width: 34px;
          height: 32px;
          border-radius: 9px;
        }

        &-text{
          width: 10%;

          &-art{
            font-weight: var(--light-weight);
          }
        }
      }

      &-item > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-item > span:nth-child(3) {
        width: 45%;
      }

      &-item > span:nth-child(6) {
        width: 8%;
      }

      &-item:hover {
        background-color: var(--purple-50);
        cursor: pointer;
      }
  }
}

  &__btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;

    &_wrap{
      display: flex;
      gap: 15px;
      justify-content: space-between;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}