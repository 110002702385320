.AhtungModalVersion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 400px;
  max-height: 680px;
  padding: 20px 26px;

  &__body {
    width: 90%;
    height: 45%;

    &-decription {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        margin-bottom: 15px;
        color: var(--purple-400);
        font-size: 24px;
        text-align: center;
      }

      &-body {
        text-align: center;

        &_link:hover {
          color: var(--purple-400);
        }
      }

      &-body_text {
        margin-top: 5px;
      }
    }
  }

  &__list {
    margin-top: 5px;

    &-item {
      margin-top: 10px;
      font-size: 15px;
      text-align: start;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 45px;
  }
}