.newProjectOrLoad {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 625px;
  height: 100%;
  max-height: 215px;


  &__header {
    display: flex;
    justify-content: center;
    width: 100%;

    &-title {
      font-size: 18px;
      line-height: 22px;

    }
  }

  &__body {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    padding-inline: var(--modal-indent);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
