@import "../../../../styles/mixins.scss";

.materialResidues {
  width: calc(100% - 106px);
  min-width: 1024px;
  height: calc(100% - 100px);
  border-radius: var(--modal-radius);
  overflow: hidden;
  background: var(--gray-50);
  box-shadow: var(--modal-shadow);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 19.50px 15px 28px 25px;
  }

  &__materialOption {
    height: 224px;
    margin-bottom: 10px;
    padding: 21px 25px 22px;
    background-color: var(--white-100);
  }

  &__materialOption-title {
    line-height: 19px;
  }

  &__materialOption-mainContent {
    padding: 30px 0 38px;
  }

  &__materialOption-tableHeader {
    display: flex;
    gap: 25px;
    padding-bottom: 9px;
  }

  &__tableHeader-item {
    font-size: 10px;
    text-align: center;
    opacity: 0.6;

    @include for-desktop-up {
    font-size: 14px;
    }
  }

  &__materialOption-tableBody {
    display: flex;
    gap: 25px;
  }

  &__materialOption-select {
      width: 100%;
      max-width: 272px;
  }

  &__materialOption-item {
    width: 100%;

    @include for-fullHD-up {
      max-width: 194px;
    }
  }

  &__materialOption-input {
    height: 38px;
    padding: 9px 10px 10px;
    border: var(--border-width-medium) solid transparent;
    line-height: 19.2px;
    background-color: var(--form-field-bg);

    &:focus{
      background: #f0e4ed;
      outline: none;
    }
  }

  &__select{
    width: 100%;
    height: 100%;
    border: var(--border-width-medium) solid transparent;
    background: var(--form-field-bg);
  }

  &__materialOption-checkBox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 320px;
    height: 38px;
    padding: 9.5px 14px;
    background: var(--gray-50);
  }

  &__materialOption-texture {
    max-width: 226px;
  }

  &__materialOption-checkBox-text{
    font-size: 10px;

    @include for-desktop-up {
      font-size: 14px;
    }
  }

  &__materialOption-footer {
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__loader{
    height: calc(100% - 382px);
  }

  &__loader2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
  }

  &__dontHaveMaterial{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 382px);
  }

  &__table {
    height: calc(100% - 382px);
    overflow: auto;
  }

  &__tableHeader {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 37px;
    padding-right: 16px;
    background: var(--white-100);
  }

  &__checkBoxContainer {
    width: 20px;
    padding-right: 50px;
    padding-left: 38px;
  }

  &__itemContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: flex-end;
    height: 77.5px;
    padding-right: 25px;
  }

  &__checkBox{
    width: 20px;
    height: 20px;
  }
}
