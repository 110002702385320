.lang {
  position: relative;
  text-transform: uppercase;
  cursor: pointer;

  &--current {
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--font-size-large);

    &::before {
      position: absolute;
      top: 50%;
      right: -1rem;
      width: 0.7rem;
      height: 0.6rem;
      background-image: url("../../../media/lang-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: translateY(-50%) rotate(0);
      transition: transform var(--transition);
      content: "";
    }
  }

  &__tooltip {
    &.react-tooltip {
      padding: initial;
    }
  }

  &--opened {
    .lang--current {
      &::before {
        transform: translateY(-50%) rotate(-180deg);
        transition: transform var(--transition);
      }
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 15px;
    border: none;
    color: var(--dark-900);
    font-size: var(--font-size-large);
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    transition: background-color var(--transition);

    &.active {
      color: var(--purple-400);
    }

    &:first-child {
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }

    &:last-child {
      border-bottom-right-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }

    @media (hover) {
      &:hover {
        background-color: var(--purple-100);
        transition: background-color var(--transition);
      }
    }
  }
}